import React from 'react'
import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConsultationSelector = ({animal, consultation, setConsultation, disable}) => {
  const { t, i18n } = useTranslation();
  return (<Autocomplete
      disabled={disable}
      disablePortal
      disableClearable
      onChange={(event, newValue) => setConsultation(newValue)}
      sx={{ width: 500, my: 5, mx: 2 }}
      value={consultation === undefined ? null : consultation}
      options={animal?.consultations ? animal?.consultations : []}
      getOptionLabel={selectOptionTitre}
      renderInput={(params) => <TextField {...params} label={t('consultation.selectorTitle')}/>}
    />)
}

const selectOptionTitre = (option) => {
  return "#" + option.no + "  " + option.date
}

ConsultationSelector.propTypes = {
  animal: PropTypes.object.isRequired,
  consultation: PropTypes.object.isRequired,
  setConsultation: PropTypes.func.isRequired,
  disable: PropTypes.bool
};


export default ConsultationSelector