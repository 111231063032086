import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GetUserIcon } from '../../services/authenticate';
import { useTranslation } from 'react-i18next';

function Top(props) {
  const [t, i18n] = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userMenuOpen = Boolean(anchorEl);
  const { onDrawerToggle } = props;

  const userIcon = GetUserIcon(props.username)
  let navigate = useNavigate(); 

  const LOGOUT_ID = "logout"
  const handleUserClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUserClose = (event) => {
    setAnchorEl(null);
    if (event && event.target.id === LOGOUT_ID) {
      navigate("/logout")
    }

  };


  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center">
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs />
            <Grid item visibility="hidden">
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              
              <Tooltip title={props.username}>
                <IconButton color="inherit" sx={{ p: 0.5 }} onClick={handleUserClick}>
                  {userIcon}
                </IconButton>
              </Tooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={userMenuOpen}
                onClose={handleUserClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem id={LOGOUT_ID} onClick={handleUserClose}>{t('session.logout')}</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

Top.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Top;
