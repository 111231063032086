import { formatToSystemDate } from '../../date/DateUtils';
const emptyVaccin = {"isActif": true, 
    "date": formatToSystemDate(new Date()),
    "items":[], 
    "no": "",
    "consultationNo": ""
}


const emptyItem = {"name":"Imrab 3","effective": formatToSystemDate(new Date()) ,
    "rappel": formatToSystemDate(new Date().setFullYear(new Date().getFullYear() + 1))}


export { emptyVaccin, emptyItem }