import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import EventsConfirm from "../../api/EventsConfirm"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EventConfirmSelector = ({eventConfirmKey, setEventConfirm, setEventConfirmKey, disable}) => {
  const { t, i18n } = useTranslation();
  const [optionsEvents, setOptionsEvents] = useState([])

    if (optionsEvents.length === 0) {
      new EventsConfirm().List().then(setOptionsEvents)
    }

    const internalSetEvent = (ev, newValue) => {
      if (setEventConfirmKey) {
        setEventConfirmKey(newValue)
      }
      new EventsConfirm().Get(newValue).then(setEventConfirm)
    }
  
    return (<Autocomplete
        disabled={disable}
        disableClearable
        onChange={internalSetEvent}
        sx={{ width: 500 }}
        value={eventConfirmKey === undefined ? null : eventConfirmKey}
        options={optionsEvents}
        getOptionLabel={selectOptionTitre}
        renderInput={(params) => <TextField {...params} label={t('eventConfirm.selectorTitle')}/>}
      />)
  }
  
  const selectOptionTitre = (option) => {
    if (!option) {
      return null
    }

    let displayValue = "# " + option.substring(option.indexOf("/") + 1).replaceAll("___", " - ").replaceAll("_", " ");
    return displayValue;
  }

  EventConfirmSelector.propTypes = {
    eventConfirmKey: PropTypes.object,
    setEventConfirm: PropTypes.func.isRequired,
    setEventConfirmKey: PropTypes.func,
    disable: PropTypes.bool
  };

  export default EventConfirmSelector