import React from 'react'
import { Paper, TextField } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DayPicker from '../../date/DatePicker';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import { DeleteIcon } from '../../common/IconFactory';


function PrescriptionTable({items, handleInputChange, deleteItem, isPrintMode, isEditMode, itemType}) {
    const rows = isEditMode ? getEditRow(items, handleInputChange, deleteItem, itemType) : getViewRow(items, itemType)


    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {itemType !== "3" && <TableCell ><b>Médicament</b></TableCell>}
              {itemType !== "3" && <TableCell ><b>Posologie</b></TableCell>}
              {itemType === "2" && <TableCell  align="center"><b>Expiration</b></TableCell>}
              {isEditMode && <TableCell width="44px" align="right"></TableCell> }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


function getEditRow(items, handleInputChange, deleteItem, itemType) {
    return (
      items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          {(itemType === "3") && 
            <TableCell align="left" component="th" scope="row" width={(itemType !== "3") ? "40%": "90%"}>
              <TextField label="Détail" 
                required
                fullWidth
                multiline
                maxRows={8}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                value={row.name} variant="standard"/>
            </TableCell>
          }   
          {(itemType !== "3") && 
            <>
            <TableCell align="left"  width="40%">
              <TextField label="Nom du médicament" 
                required
                fullWidth
                inputProps={{ maxLength: 60 }}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                value={row.name} variant="standard"/>
            </TableCell>
            <TableCell align="left" >
              <TextField label="Posologie du médicament" 
                required
                fullWidth
                multiline
                maxRows={8}
                onChange={(e) => handleInputChange(index, 'posologie', e.target.value)}
                value={row.posologie} variant="standard"/>
            </TableCell>
            </>
          }
          {(itemType === "2") && 
              <td>
                <DayPicker 
                  inputChange={(e) => handleInputChange(index, 'expiration', convertToSystemDateFormat(e))} 
                  minDate={new Date()}
                  required={itemType === "2"} 
                  value={row.expiration}/>
              </td>
          }
          <TableCell align="center" padding='none'><DeleteIcon key={'btnDelete'+index} onClick={(e) => deleteItem(index)}/></TableCell>
        </TableRow>
      )))
}

function getViewRow(items, itemType) {
    return (
      items.map((row, index) => (
        <TableRow 
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell component="th" scope="row" size='small' width={itemType !== "3" ? "40%": "90%"}>
              <pre>{ row.name}</pre>
          </TableCell>
          {itemType !== "3" &&
            <TableCell size='small'>
              { row.posologie}
            </TableCell>
          } 
          {itemType === "2" &&
            <TableCell align="center" size='small'>
              { row.expiration}
            </TableCell>
          }
        </TableRow>
      )))
}

export default PrescriptionTable