import React, { useState } from 'react'

import EmptyView from '../view/EmptyView';
import EventSelector from './EventSelector';
import { Button, FormControlLabel, Paper, Switch, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { convertToSystemDateHourFormat, formatTimeToDisplayFormat } from '../date/DateUtils';
import { GetAnimalSection, GetClientSection, GetConsultSection, GetManageSection } from './EventsTable';
import { emptyEventConfirm } from '../eventsConfirm/BaseEventConfirm';
import { handleInputChange } from '../view/TableEditionHandler';
import AnimalSelector from '../animals/AnimalSelector';
import Animals from '../../api/Animals';
import Clients from '../../api/Clients';
import { GetCommon, ReserveAnimalNo, ReserveClientNo } from '../../api/Common';
import { convertEventToAnimal, convertEventToClient } from './BaseEvent';
import { messageStore } from '../common/InfoMessage';
import { CalendarUpdate } from '../../api/Calendar';
import ClientSelector from '../clients/ClientSelector';
import { emptyClient } from '../clients/BaseClient';
import { emptyAnimal } from '../animals/BaseAnimal';
import Events from '../../api/Events';
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import { useDispatch } from 'react-redux';

const EVENT_KEY_SEPERATOR = "---"

export function ViewEvents({navigateAction, handleEdit}) {
  const dispatch = useDispatch()
  const [newEventConfirm, setNewEventConfirm] = useState(emptyEventConfirm);
  
  const [client, setClient] = useState(null);
  const [animal, setAnimal] = useState(null);

  const [eventKey, setEventKey] = useState(null);
  const [curEvent, setCurEvent] = useState(null);


  if (curEvent === undefined || curEvent === null) {
    return (
      <EmptyView title='Veillez sélectionner un événement'>
        <EventSelector event={curEvent} setEventKey={setEventKey} setEvent={setCurEvent}/>
      </EmptyView>
    )
  }

  const setupAnimal = (selectedAnimalKey) => {
    //if (selectedAnimalKey !== animalKey) {
        new Animals().Get(selectedAnimalKey).then((data) => handleInputChange(newEventConfirm, setNewEventConfirm, null, "animal", data) )
    //}
  }

  console.log("ViewEvents", curEvent)
  return getView(eventKey, curEvent, newEventConfirm, setNewEventConfirm, setupAnimal, dispatch)
}



const getCreateEventDate = (key) => {
  if (key !== null && key !== undefined && key.includes(EVENT_KEY_SEPERATOR)) {
    const epoch = key.split(EVENT_KEY_SEPERATOR)[1]
    return formatTimeToDisplayFormat(new Date(Number(epoch)))
  }
  
  return key
}



const getView =(eventKey, curEvent, newEventConfirm, setNewEventConfirm, setupAnimal, dispatch) => {
  return (
    <>
    <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
    <b>Date de l'événement : </b> 
      <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
        <b>Date de la consultation : </b> 
        {curEvent.emergency === false &&
          curEvent.selectedDate
        } 
        {curEvent.emergency === true &&
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker onChange={(e) => changeDate(e, newEventConfirm, setNewEventConfirm)}  />
          </LocalizationProvider>
        }
      </Typography>
      <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
        <b>Date de création de la demande : </b> 
        { getCreateEventDate(eventKey)}
      </Typography>
  
    <GetClientSection event={curEvent} />
    <GetAnimalSection event={curEvent} />

    <GetConsultSection consultEvent={curEvent} />
    <GetManageSection 
      allowNewClient={true}
      onChange={(fieldName, value) => handleInputChange(newEventConfirm, setNewEventConfirm, null, fieldName, value) }
      sendZoomLink={newEventConfirm.sendZoomLink}
      sendCalendarEvent={newEventConfirm.sendCalendarEvent}
      sendEmail={newEventConfirm.sendEmail}
      isNewClient={newEventConfirm.isNewClient}
      />
    
    {!newEventConfirm.isNewClient && 
    <>
      <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
          <b>Sélection du client existant : </b> 
            <ClientSelector 
                setClient={(clientData) => handleInputChange(newEventConfirm, setNewEventConfirm, null, "client", clientData)}
                loadClient={true}
                />

          <FormControlLabel control={<Switch id="newAnimal" onChange={(event) => handleInputChange(newEventConfirm, setNewEventConfirm, null, "isNewAnimal", event.target.checked)} defaultChecked checked={newEventConfirm.isNewAnimal} />} label="Nouvel animal" />
          { !newEventConfirm.isNewAnimal &&
          
            <AnimalSelector
              clientNo={newEventConfirm.client.no}
              setAnimal={(data) => handleInputChange(newEventConfirm, setNewEventConfirm, null, "animal", data)}
              loadAnimal={true}
              />


              
          }
      </Typography>
     
    </>
    }
      <Button variant="contained"
        onClick={() => {acceptAsk(eventKey, curEvent, newEventConfirm, dispatch)}} >Accepter la demande</Button>
             
     </Paper>
     </>)
}



const changeDate = (e, newEventConfirm, setNewEventConfirm) => {
  const dtFormat = convertToSystemDateHourFormat(e)
  console.log("changeDate", dtFormat)
  const newEvent = {...newEventConfirm,
    calendar :{...newEventConfirm.calendar,
        startDate: dtFormat,
        startDateDisplay: dtFormat
      }
  }
  setNewEventConfirm(newEvent)
}

const confirmEvent = async ({eventKey, curEvent, newEventConfirm}) => {
  var finalEvent  =  {...newEventConfirm,
    event: curEvent
  }
  if (newEventConfirm.isNewClient) {
    const newClient = await createNewClient(curEvent)
    console.log("new client : ", newClient)
    finalEvent = {...finalEvent, client: newClient}
    
  }
  if (newEventConfirm.isNewClient || newEventConfirm.isNewAnimal) {
    const newAnimal = await createNewAnimal(curEvent, finalEvent.client.no)
    console.log("new animal : ", newAnimal)
    finalEvent = {...finalEvent, animal: newAnimal}
  } 
  console.log("Final eventConfirm data : ", finalEvent)
  CalendarUpdate(finalEvent)
    .then((result) => {
      if (result.status === 200) {
        new Events().delete(eventKey)
        messageStore.sendMessage("Le workflow de la demande est démarré")
      } else {
       messageStore.sendMessage(result.data, "error", "Erreur lors de la création du workflow")
    }
    })
  
}


const createNewClient = (originalEvent) => {
  return new GetCommon()
  .then((common) => {return common.clientNo})
  .then(ReserveClientNo)
  .then((clientNo) => {return convertEventToClient(originalEvent, clientNo)})
  .then((clientData) => {return new Clients().Insert(clientData)})
  .then((result) => {
    if (result.status === 200) {
      messageStore.sendMessage("Client créé avec succès")
      return result.data
    } else {
      messageStore.sendMessage(result.statusText, "error","Erreur lors de la création")
    }
    return {}
  })
} 

const createNewAnimal = (originalEvent, clientNo) => {
  return new GetCommon()
  .then((common) => {return common.animalNo})
  .then(ReserveAnimalNo)
  .then((animalNo) => {return convertEventToAnimal(originalEvent, animalNo, clientNo)})
  .then((clientData) => {return new Animals().Insert(clientData)})
  .then((result) => {
    if (result.status === 200) {
      messageStore.sendMessage("Animal créé avec succès")
      return result.data
    } else {
      messageStore.sendMessage(result.statusText, "error","Erreur lors de la création")
    }
    return {}
  })

} 


const acceptAsk = (eventKey, curEvent, newEventConfirm, dispatch) => {
  dispatch(addLoadingCount())
  validation(eventKey, curEvent, newEventConfirm)
    .then(confirmEvent)
    .finally(()=>dispatch(subLoadingCount()))
}

const validation = (eventKey, curEvent, newEventConfirm) => {
  return new Promise((resolve, reject) => {
    if (!newEventConfirm.isNewClient && newEventConfirm.client && JSON.stringify(newEventConfirm.client) === JSON.stringify(emptyClient)) {
      reject({ client: "Vous devez sélectionner un client existant" });
      messageStore.sendMessage("Vous devez sélectionner un client existant", "warning")
    } else if (!newEventConfirm.isNewClient && !newEventConfirm.isNewAnimal && newEventConfirm.animal  && JSON.stringify(newEventConfirm.animal) === JSON.stringify(emptyAnimal)) {
      reject({ animal: "Vous devez sélectionner un animal existant" });
      messageStore.sendMessage("Vous devez sélectionner un animal existant", "warning")
    }
    resolve({eventKey: eventKey, curEvent: curEvent, newEventConfirm: newEventConfirm})
  });
};