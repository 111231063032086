import React  from 'react';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';



function DayPicker({value, inputChange, label, required, minDate, maxDate}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={label}
            inputFormat="YYYY-MM-DD"
            required={required}
            minDate={minDate ? dayjs(minDate) : null}
            maxDate={maxDate ? dayjs(maxDate) : null}
            value={value ? dayjs(value) : null}
            onChange={inputChange}
           />
        </LocalizationProvider>
    )
}


export default DayPicker