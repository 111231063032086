import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import Events from "../../api/Events"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EventSelector = ({event, setEvent, setEventKey, disable}) => {
  const { t, i18n } = useTranslation();
  const [optionsEvents, setOptionsEvents] = useState([])

    if (optionsEvents.length === 0) {
      new Events().List().then(setOptionsEvents)
    }

    const internalSetEvent = (ev, newValue) => {
      setEventKey(newValue)
      new Events().Get(newValue).then(setEvent)
    }
  
    return (<Autocomplete
        disabled={disable}
        disableClearable
        onChange={internalSetEvent}
        sx={{ width: 500 }}
        value={event === undefined ? null : event}
        options={optionsEvents}
        renderInput={(params) => <TextField {...params} label={t('event.selectorTitle')}/>}
      />)
  }
  
  EventSelector.propTypes = {
    event: PropTypes.object,
    setEvent: PropTypes.func.isRequired,
    setEventKey: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };

  export default EventSelector