import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import { useState } from "react"
import Headers from "../../api/Headers"
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HeaderSelector = ({header, setHeader, disable}) => {
  const { t, i18n } = useTranslation();
  const [optionsHeaders, setOptionsHeaders] = useState([])

    if (optionsHeaders.length === 0) {
      new Headers().List().then(setOptionsHeaders)
    }

    const internalSetHeader = (ev, newValue) => {
      new Headers().Get(newValue).then(setHeader)
    }
  
    return (<Autocomplete
        disabled={disable}
        disableClearable
        onChange={internalSetHeader}
        sx={{ width: 500, my: 5, mx: 2 }}
        value={header === undefined ? null : header}
        options={optionsHeaders}
        renderInput={(params) => <TextField {...params} label={t('header.selectorTitle')}/>}
      />)
  }
  
  HeaderSelector.propTypes = {
    header: PropTypes.object.isRequired,
    setHeader: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };

  export default HeaderSelector