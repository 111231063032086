import { createSlice } from '@reduxjs/toolkit'


export const clientSlice = createSlice({
  name: 'client',
  initialState: {
    clientNo: null,
    client: null,
    originalClientKey: null,
    animalNo: null,
    animal: null,
    animalsFilter: null
  },
  reducers: {
    setClient: (state, clientData) => {
      if (JSON.stringify(state.client) !== JSON.stringify(clientData)) {
        console.log("store : setClient", clientData)
        state.client = clientData
        if (clientData.payload) {
          state.clientNo = clientData.payload.clientNo
          state.originalClientKey = clientData.payload.key
        } else {
          state.clientNo = null
          state.originalClientKey = null
        }
        state.animal = null
      }
    },
    setAnimal: (state, animalData) => {
      if (JSON.stringify(state.animal) !== JSON.stringify(animalData)) {
        console.log("store : setAnimal, ", animalData)
        state.animal = animalData
      }
    },
    setAnimalsFilter: (state, animalData) => {
      if (JSON.stringify(state.animalsFilter) !== JSON.stringify(animalData)) {
        console.log("store : setAnimal, ", animalData)
        state.animalsFilter = animalData
      }
    }
  }
})

const filterAnimals = (animals, clientNo) => {
  const filteredArray = animals.filter(element => element.clientNo === clientNo);
  return filteredArray
}

// Action creators are generated for each case reducer function
export const { setClient, setAnimal, setAnimalsFilter } = clientSlice.actions

export default clientSlice.reducer