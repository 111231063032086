
import { userStore } from "../component/common/Session";
import userpool from "../component/userpool";



export const GenerateSession = async(currentSession) => {
    const token = currentSession?.idToken
    
    if (userpool.getCurrentUser() === null) {
        console.log("You are currently not loggin")
        return null
    }
  
    if (token !== null && token !== undefined) {
      //Validate current token
      if (new Date().getTime()/1000 > token.payload.exp) {
        return await internalFetchSession()
      }
    } else {
        return  await internalFetchSession()
    }
}



const internalFetchSession = async () => {
    console.log("Fetch new token")
    const session = await userpool.getCurrentUser()?.getSession((error, session) => {
        if (error !== null) {
          console.log("fetchNewAccessToken : ", error)
          alert(error);
          return null
        } else {
          console.log("New token define!")
            return session
        }
      });
    return session
}

export const Logout = () => {
   // const dispatch = useDispatch()
    
    const user = userpool.getCurrentUser();
    user.signOut();
    //dispatch(setAnimal(null))
    //dispatch(setUser({}))
    userStore.setUser({})
    window.location.href = '/';
};