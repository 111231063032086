import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FlatHeaderRenderer from '../header/FlatHeaderRenderer';
import { formatToDisplayFormatFromString } from '../date/DateUtils'
import {formatToSystemDate} from '../date/DateUtils'
import {messageStore} from '../common/InfoMessage'
import FactureTable from './FactureTable';
import { useSelector } from 'react-redux';
import {ViewButtonSection} from '../view/ViewButtonSection';
import { handleDownload } from '../GeneratePDF';
import Clients from '../../api/Clients';
import EmptyView from '../view/EmptyView';
import BillSelector from './BillSelector';
import { extraSection } from './BaseFacture';
import { useTranslation } from 'react-i18next';

const handlePaid = (bill, curClient, t) => {
  console.log("Pay the bill")
  var updateBill = JSON.parse(JSON.stringify(bill))
  updateBill.isPaid = true
  updateBill.paidDate = formatToSystemDate(new Date())
  
  var clientUpdate = JSON.parse(JSON.stringify(curClient))
  console.log("CurBill : ",  clientUpdate.bills)
  const objIndex = clientUpdate.bills.findIndex(obj => obj.no === updateBill.no);

  clientUpdate.bills[objIndex] = updateBill
  console.log("New bill : ",  clientUpdate.bills)

  new Clients().Update(clientUpdate).then((result) => {
    if (result.status === 200) {
      messageStore.sendMessage(t('billing.paySaveSuccess'))
      } else {
        messageStore.sendMessage(result.statusText, "error", t('saveError'))
      }
  })
}


export function ViewBill({itemNo, handleEdit}) {
  const [t, i18n] = useTranslation()
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const [bill, setBill] = useState(itemNo && itemNo >= 0 ? curClient?.payload?.bills?.find(obj => obj.no === itemNo) : null);

  if (bill && (curClient?.payload?.bills === undefined ||  curClient.payload.bills.findIndex(obj => obj.no === bill.no) === -1)) {
    console.log("Change selected bill")
    setBill(null)
    return (<GetEmptyView client={curClient} setItem={setBill}/>)
  }
  
  const getPdfName = () => {
  const baseName = bill.no + "-" + curClient.payload.name 
      return bill.animal?.name ? baseName + " - " + bill.animal?.name + ".pdf" : baseName + ".pdf"
  }
  

  const getView =(header, client, bill, isPrintMode) => {
    return (
        <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
          <Typography color="text.secondary" align='center'>
            <BillSelector client={client} bill={bill} setBill={setBill}/>
          </Typography>
          <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
    
            <FlatHeaderRenderer 
              displayHeaderLeft={true}
              isPrintMode={isPrintMode}
              headerData={header} 
              clientData={client} 
              animalData={bill ? bill.animal : null}
              extraData={{"title":  t('billing.item.title') + " :",
                "noTitle": t('billing.item.no'),
                "no": bill.no,
                "date": formatToDisplayFormatFromString(bill.date)}}
              extraChildren={extraSection(bill, header?.preference)}  
            />
            
      
            <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
              {t('billing.mainTitle')}
            </Typography>
                  
            <FactureTable 
              items={bill.items} 
              isEditMode={false}
              isPrintMode={isPrintMode}/>
            
            <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" variant="h6">
              {t('billing.totalLong')} : {bill.total} $
            </Typography>
          </div>
        { (bill.isPaid === undefined || bill.isPaid === false ) && 
            <Button variant="contained"
                onClick={() => {
                  handlePaid(bill, curClient.payload, t);
                }} >{t('billing.payBill')}</Button>
        }
          <ViewButtonSection isEditMode={!bill.isPaid} 
            selectedItem={bill}
            handleEdit={() => handleEdit(bill)}
            handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
            />
        </Paper>
    );
  }

  if (bill === undefined || bill === null) {
    return (<GetEmptyView client={curClient} setItem={setBill}/>)
  }

  return getView(header?.payload, curClient.payload, bill, isPrintMode)
    
}

const GetEmptyView = ({client, setItem}) => {
  const { t, i18n } = useTranslation();
  return (
    <EmptyView title={t('billing.noSelection')}>
      <BillSelector client={client?.payload} setBill={setItem}/>
    </EmptyView>
  )
}

