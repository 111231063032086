import React, { useState } from 'react'
import { handleDownload } from '../GeneratePDF';
import EmptyView from '../view/EmptyView';
import { Paper, Typography } from '@mui/material';

import HeaderSelector from './HeaderSelector';
import { ViewButtonSection } from '../view/ViewButtonSection';
import { useTranslation } from 'react-i18next';

export function ViewHeader({navigateAction, handleEdit}) {
  const [t, i18n] = useTranslation()
  const pdfContentRef = React.createRef();
  const [isPrintMode, setIsPrintMode] = useState(false);

  const [curHeader, setCurHeader] = useState(null);

  const getPdfName = () => {
    return "header.pdf"
  }


  if (curHeader === undefined || curHeader === null) {
    return (
      <EmptyView title={t('header.noSelection')}>
        <HeaderSelector setHeader={setCurHeader}/>
      </EmptyView>
    )
  }


  const getView =(curHeader, isPrintMode) => {
    return (
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
            {t('header.description')}
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
              <b>Nom : </b> {curHeader.name}
              <br/>
              <b>{t('header.phone')} : </b> <a href={"tel:" + curHeader.phonee}>{curHeader.phone}</a>
              <br/>
              <b>{t('header.addr1')} : </b> {curHeader.addr1}
              <br/>
              <b>{t('header.addr2')} : </b> {curHeader.addr2}
              <br/>
              <b>{t('header.email')} : </b> <a href={"mailto:" + curHeader.email}>{curHeader.email}</a>
            </Typography>
          

          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left" variant='h5'>
          {t('header.config')}
            </Typography>
            <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
              <div><pre>{JSON.stringify(curHeader.preference, null, 2) }</pre></div>
            </Typography>
            
            <ViewButtonSection 
              isEditMode={false}
              selectedItem={curHeader}
              handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
          />
          </div>
        </Paper>
      )
  }

    return getView(curHeader, isPrintMode)
}

