import React  from 'react';
import { Grid, Typography } from "@mui/material";
import { RefreshIconTT } from "../common/IconFactory";
import Clients from "../../api/Clients";


function updateData(clientData) {
    console.log("Update current data");
    return new Clients().Get(clientData.key).then((data) => {
        console.log("Data updated");
        const curAnimal = clientData.animal
        clientData = data;
        clientData.animal = curAnimal;

        return clientData
    })
}


function ClientCard({clientData, isPrintMode, canRefresh, updateCallback}) {
    return <>
            { clientData &&  
            v2(clientData, isPrintMode, canRefresh, updateCallback)
            }
            </>
}
        

function v1(clientData, isPrintMode, canRefresh, updateCallback) {
    return (
        <Typography sx={{ my: 0, mx: 2 }} color="text.secondary" align="left">
                <table>
                <tr>
                    <td colSpan="2"><h3>Informations du client 
                        {(canRefresh && ! isPrintMode) && 
                            <RefreshIconTT  onClick={(e) =>{ 
                                updateData(clientData).then((data) => updateCallback(data))
                             }} />}</h3> </td>
                </tr>
                <tr>
                    <td>Nom :</td>
                    <td>{clientData.name}</td>
                </tr>
                <tr>
                    <td>Adresse :</td>
                    <td>
                    {isPrintMode && clientData.addr1} 
                    {!isPrintMode && <a target="_blank" href={"http://maps.google.com/?q=" + clientData.addr1 + " " + clientData.addr2} rel="noreferrer">{clientData.addr1}</a>}
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                    {isPrintMode && clientData.addr2} 
                    {!isPrintMode && <a target="_blank" href={"http://maps.google.com/?q=" + clientData.addr1 + " " + clientData.addr2} rel="noreferrer">{clientData.addr2}</a>}  
                    </td>
                </tr>
                <tr>
                    <td>Téléphone :</td>
                    <td> 
                        {isPrintMode && clientData.phone} 
                        {!isPrintMode && <a href={"tel:" + clientData.phone}>{clientData.phone}</a>}
                    </td>
                </tr>
                <tr>
                    <td>Courriel :</td>
                    <td> 
                        {isPrintMode && clientData.email} 
                        {!isPrintMode && <a href={"mailto:" + clientData.email}>{clientData.email}</a>}
                    </td>
                </tr>
                </table>
            </Typography>
    )
}
function v2(clientData, isPrintMode, canRefresh, updateCallback) {
    return (
    <Grid container spacing={0} columns={10} 
        className='header-grid-container' 
        sx={{ my: 0, mx: 0, color: 'gray', textAlign: 'left', typography: 'body2' }}>
        <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1'}}>
        <Typography sx={{ my: 1 }} variant="h6">
            Informations du client
        </Typography>
        </Grid>

        <Grid item xs={4}>Nom :</Grid>
        <Grid item xs={5} >{clientData.name}</Grid>

        <Grid item xs={4} >Adresse :</Grid>
        <Grid item xs={6} >{isPrintMode && clientData.addr1} 
            {!isPrintMode && <a target="_blank" href={"http://maps.google.com/?q=" + clientData.addr1 + " " + clientData.addr2} rel="noreferrer">{clientData.addr1}</a>}
        </Grid>
        
        <Grid item xs={4} >Adresse :</Grid>
        <Grid item xs={6} >
            {isPrintMode && clientData.addr2} 
            {!isPrintMode && <a target="_blank" href={"http://maps.google.com/?q=" + clientData.addr1 + " " + clientData.addr2} rel="noreferrer">{clientData.addr2}</a>}
        </Grid>


        <Grid item xs={4} >Téléphone :</Grid>
        <Grid item xs={6} >
            {isPrintMode && clientData.phone} 
            {!isPrintMode && <a href={"tel:" + clientData.phone}>{clientData.phone}</a>}
        </Grid>

        <Grid item xs={4} >Courriel :</Grid>
        <Grid item xs={6} >
            {isPrintMode && clientData.email} 
            {!isPrintMode && <a href={"mailto:" + clientData.email}>{clientData.email}</a>}
        </Grid>
    </Grid>
    )
}
export default ClientCard