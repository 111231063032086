import React  from 'react';
import {CreateConsultation} from './CreateConsultation'
import {ViewConsultation} from './ViewConsultation'
import { useState } from "react"
import ClientHeader from "../../layout/ClientHeader"
import TabSelector from "../../layout/TabSelector"
import { GetRequiredView } from '../../Navigator'
import { useTranslation } from 'react-i18next';
import { GetAllHeader, GetNoSelectionHeader } from '../../view/HeadersUtils';

const ConsultationManager = () => {
  const { t, i18n } = useTranslation();
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))

  const handleEdit = (item) => {
    setItemToEdit(item)
    setAvailViews(GetAllHeader(t))
    setCurrentView("edit")
  }
  const saveCallback = (item) => {
    setItemToEdit(null)
    setItemNoToView(item.no)
    setAvailViews(GetNoSelectionHeader(t))
    setCurrentView("view")
  }
    return (
        <>
        <ClientHeader allowSelection={currentView !== "edit"}/>
        <TabSelector 
          onViewChange={setCurrentView}
          pageTitle="Consultation"
          availableView={availViews}
          currentView={currentView}
          />
          { currentView === "view" && <ViewConsultation itemNo={itemNoToView} handleEdit={handleEdit}/> }
          { currentView === "edit" && <CreateConsultation itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
          { currentView === "create" && <CreateConsultation saveCallback={saveCallback}/> }
        </>
      );
}


export default ConsultationManager