import React  from 'react';
import { Paper, TextField } from '@mui/material';
import { ProfitCalculatorDialog } from './ProfitCalculator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DeleteIcon } from '../common/IconFactory';
import { useTranslation } from 'react-i18next';


function FactureTable({items, handleInputChange, deleteItem, isPrintMode, isEditMode}) {
    const rows = isEditMode ? GetEditRow(items, handleInputChange, deleteItem) : GetViewRow(items)
    const { t, i18n } = useTranslation();

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>{t('billing.item.item')}</b></TableCell>
              <TableCell align="right"><b>{t('billing.item.qt')}</b></TableCell>
              <TableCell align="right"><b>{t('billing.item.amount')}</b></TableCell>
              <TableCell align="right"><b>{t('billing.item.total')}</b></TableCell>
              {isEditMode && <TableCell align="right"></TableCell> }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


const GetEditRow = (items, handleInputChange, deleteItem) => {
  const { t, i18n } = useTranslation();
    return (
        items.map((row, index) => (
        <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row">
                <TextField label={t('billing.item.itemLong')}
                required
                fullWidth
                inputProps={{ maxLength: 60 }}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                value={row.name} variant="standard"/>
            </TableCell>
            <TableCell align="right"> 
            <input
                type="number"
                required
                value={row.quantite} min={0} max={9999}  maxLength={3}
                onChange={(e) => handleInputChange(index, 'quantite', e.target.value)}
            />
            </TableCell>
            <TableCell align="right">
                <input
                    type="number"
                    required
                    value={row.unitPrice} placeholder="1" step="0.01" min={0}  maxLength={8}
                    onBlur={ (e) => handleInputChange(index, 'unitPrice', parseFloat(e.target.value).toFixed(2))}
                    onChange={(e) => handleInputChange(index, 'unitPrice', e.target.value)}
                />
                <ProfitCalculatorDialog key={'dialogProfit'+ index} prixCourant={row.unitPrice}/>
            </TableCell>
            <TableCell align="right">{row.totalPrice}$</TableCell>
            <TableCell align="center"><DeleteIcon key={'btnDelete'+index} onClick={(e) => deleteItem(index)}/></TableCell>
        </TableRow>)
        ))
}


const GetViewRow = (items) => {
    return (
        items.map((row, index) => (
        <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell component="th" scope="row" size='small'>
                {row.name}
            </TableCell>
            <TableCell align="right" size='small'> 
                {row.quantite}
            </TableCell>
            <TableCell align="right" size='small'>{row.unitPrice}$</TableCell>
            <TableCell align="right" size='small'>{row.totalPrice}$</TableCell>
        </TableRow>)
        ))
}

export default FactureTable