import React  from 'react';
import { Button } from "@mui/material";
import { AddItemIconContent, SaveIconContent } from "../common/IconFactory";
import PropTypes from 'prop-types';

export function CreateButtonSection({addRowHandler}){
    return (<>
      {addRowHandler && getAddRowButton(addRowHandler)}
      &nbsp;  
      {getSaveButton()}
    </>)

  } 

  function getSaveButton(){
    return (
      <Button variant="contained" type='submit'>
        <SaveIconContent/>Sauvegarder</Button>
    )

  } 
  function getAddRowButton(addRowHandler){
    return (
      <Button variant="contained"
        onClick={() => {
            addRowHandler();
        }} ><AddItemIconContent/>Ajouter une ligne</Button>
      )

  } 


  CreateButtonSection.propTypes = {
    addRowHandler: PropTypes.func
  };
  