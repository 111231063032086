const sessionObject = {}

let currentUser = {}
let userSubscribers = new Set();


const userStore = {
    getUsername() {
      // Method to get the count, this is basically getSnapshot method.
      return currentUser.username;
    },
    isAdmin() {
        return currentUser.isAdmin ? currentUser.isAdmin : false
    },
    getIdToken() {
      return currentUser.session?.idToken
    },
    getAccessToken() {
      return currentUser.session?.accessToken
    },
    getRefreshToken() {
      return currentUser.session?.refreshToken
    },
    getRestrictedClients() {
      return currentUser.restrictedClients
    },
    // Subscribe method adds the "callback" to the "subscribers" set, and
    // return a method to unsubscribe from the store.
    subscribe(callback) {
        userSubscribers.add(callback);
      return () => userSubscribers.delete(callback);
    },
    setUsername(username) {
        currentUser.username = username
    },
    setIsAdmin(isAdmin) {
        currentUser.isAdmin = isAdmin
    },
    setSession(session) {
        currentUser.session = session
    },
    setRestrictedClients(restrictedClients) {
      currentUser.restrictedClients = restrictedClients
    },
    // Method to increment the count
    setUser(user) {
      currentUser = user
      userSubscribers.forEach((callback) => callback());
    }
  };

export default sessionObject
export {userStore}