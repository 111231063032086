
import {ListElements, GetElement} from './S3Helper';

const ELEMENT_NAME = "headers"

class Headers {
    List() {
       return new ListElements(ELEMENT_NAME)
    }
 
    Get(headerName) {
       return new GetElement(ELEMENT_NAME, headerName)
    }
 
 }

export default Headers

