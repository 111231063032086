import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import Clients from "../../api/Clients"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ClientSelector = ({clientKey, setClient, setClientKey, disable, loadClient}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [optionsClients, setOptionsClients] = useState([])

    useEffect(() => {
      if (optionsClients.length === 0) {
        dispatch(addLoadingCount())
        new Clients().List()
          .then((allClients) => {
            setOptionsClients(allClients)
          })
          .finally(()=>dispatch(subLoadingCount()))
      }
    
    }, []);
      
  //console.log("In client selector : clientKey = ", clientKey)
    
  const InternalOnChange = (event, newKey) => {
    if (setClientKey) {
      setClientKey(newKey)
    }

    if (loadClient) {
      dispatch(addLoadingCount())
      new Clients().Get(newKey)
        .then(setClient)
        .finally(()=>dispatch(subLoadingCount()))
    }
  }


  return (<Autocomplete
      disabled={disable}
      disableClearable
      onChange={InternalOnChange}
      sx={{ width: 500 }}
      value={clientKey === undefined ? null : clientKey}
      options={optionsClients}
      getOptionLabel={selectOptionTitre}
      renderInput={(params) => <TextField {...params} label={t('client.selectorTitle')}/>}
    />)
  }
  

  const selectOptionTitre = (option) => {
    if (!option) {
      return null
    }

    let displayValue = "# " + option.substring(option.indexOf("/") + 1).replaceAll("___", " - ").replaceAll("_", " ");
    return displayValue;
  }

  ClientSelector.propTypes = {
    clientKey: PropTypes.string.isRequired,
    loadClient: PropTypes.bool,
    setClient: PropTypes.func.isRequired,
    setClientKey: PropTypes.func,
    disable: PropTypes.bool
  };

  export default ClientSelector