
import {ListElements, GetElement, InsertElement, UpdateElement, generateNewKey, HardDeleteElement, escapeKey} from './S3Helper';

const ELEMENT_NAME = "animals";

class Animals {
   List() {
      return new ListElements(ELEMENT_NAME)
   }

   Get(animalKey) {
      return new GetElement(ELEMENT_NAME, animalKey)
   }

   Insert(animalData) {
      const newKey = this.generateNewKey(animalData)
      animalData = {...animalData,
         key: newKey
      }

      return new InsertElement(ELEMENT_NAME, newKey, animalData)
   }
   Update(animalData) {
      const key = this.getFullKey(animalData)
      if (animalData.key !== key) {
         animalData = {...animalData,
            key: key
         }
      }

      return new UpdateElement(ELEMENT_NAME, key, animalData)
   }

   generateNewKey(animalData) {
      const clientNo = animalData.clientNo
      const elementNo = animalData.no
      const petName = animalData.name
      
      return escapeKey(clientNo + "/" + elementNo + "___" + petName).replaceAll(" ", "_") 
    }

   getFullKey(animalData) {
      if (animalData.key !== null && animalData.key !== undefined) {
         return animalData.key
      }
      return this.generateNewKey(animalData)
   }
   deleteByKey(key) {
      return new HardDeleteElement(ELEMENT_NAME, key)
   }
   delete(animalData) {
      return new HardDeleteElement(ELEMENT_NAME, this.getFullKey(animalData))
   }
}

export default Animals