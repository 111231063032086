import { createSlice } from '@reduxjs/toolkit'

export const viewSlice = createSlice({
  name: 'view',
  initialState: {
    currentPage: null,
    currentView: null,
    availableView: [],
    pageTitle: "Page Title"
  },
  reducers: {
    setView: (state, view) => {
      console.log("store : setView", view)
      state.currentView = view
    },
    setPage: (state, page) => {
      console.log("store : setPage, ", page)
      state.currentPage = page
    },
    setAvailableView: (state, views) => {
      console.log("store : setAvailableView, ", views)
      state.availableView = views
    },
    setPageTitle: (state, title) => {
      console.log("store : setPageTitle, ", title)
      state.pageTitle = title
    }
  }
})


// Action creators are generated for each case reducer function
export const { setView, setPage, setAvailableView,  setPageTitle} = viewSlice.actions

export default viewSlice.reducer