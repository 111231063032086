
import {ListElements, GetElement, InsertElement, UpdateElement, generateNewKey, HardDeleteElement} from './S3Helper';

const ELEMENT_NAME = "eventsConfirm"

class EventsConfirm {
   List() {
      return new ListElements(ELEMENT_NAME)
   }

   Get(eventConfirmKey) {
      return new GetElement(ELEMENT_NAME, eventConfirmKey)
   }

   Insert(eventConfirmData) {
      const newKey = generateNewKey(eventConfirmData.client.no, eventConfirmData.eventNo, eventConfirmData.client.name, eventConfirmData.client.animal.name)
      eventConfirmData.key = newKey
      return new InsertElement(ELEMENT_NAME, newKey, eventConfirmData)
   }

   Update(eventConfirmData) {
      return new UpdateElement(ELEMENT_NAME, this.getFullKey(eventConfirmData), eventConfirmData)
   }

   getFullKey(eventConfirmData) {
      if (eventConfirmData.key !== null && eventConfirmData.key !== undefined) {
         return eventConfirmData.key
      }
      return generateNewKey(eventConfirmData.client.clientNo, eventConfirmData.eventNo, eventConfirmData.client.name, eventConfirmData.client.animal.name)
   }
   delete(key) {
      return new HardDeleteElement(ELEMENT_NAME, key)
   }
}

export default EventsConfirm

