import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import logo from '../../../img/icon.png'
import { GetLicense } from '../../common/CommonSlice';
import PropTypes from 'prop-types';

const BIG_SIZE = 12
const REG_SIZE = 10
const SMALL_SIZE = 8


const PrescriptionCard = ({headerData, clientData, animalData, extraData, item}) => {
    const pdfContentRef = React.createRef();
    
    if (!headerData || !clientData || !animalData || !extraData || !item) {
        return (<></>)
    }

    return (
        <Box sx={{ width: 300, my: 1, mx: 1}}>
        <Card sx={{ width: 300 }}>
            <CardContent>
                <Grid container direction="row" justifyContent="space-between">
                    <Grid container direction="row" justifyContent="space-between">   
                        <img className="Card-logo" src={logo} alt="logo" width={35} height={30}/>
                    </Grid>
                    <Grid sx={{ my: 0, ml: 5}} container direction="row" justifyContent="space-between">   
                        <Typography sx={{ fontSize: REG_SIZE }} inline  align="left"><b>{headerData.name}</b>&nbsp;</Typography>
                        <Typography sx={{ fontSize: REG_SIZE }} inline  align="right">{headerData.phone}</Typography>
                    </Grid>
                    <Grid sx={{ my: 0, ml: 5}} container justify="space-between">  
                        <Typography sx={{ fontSize: SMALL_SIZE }} color="text.secondary">{headerData.addr1}&nbsp;{headerData.addr2}</Typography>
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="space-between">  
                        <Typography sx={{ fontSize: REG_SIZE }} inline ><b>{animalData.name}</b>&nbsp;/&nbsp;{clientData.name}&nbsp;</Typography>
                        <Typography sx={{ fontSize: REG_SIZE }} inline >{extraData.date}</Typography>
                </Grid>

                <Grid container direction="row" justifyContent="space-between"> 
                    <Typography sx={{ fontSize: REG_SIZE }} inline align="justify">{item.name}&nbsp;</Typography>
                    <Typography sx={{ fontSize: REG_SIZE }} inline align="justify">{item.posologie}</Typography>
                </Grid>


                <Grid container direction="row" justifyContent="space-between">
                    <Typography sx={{ fontSize: REG_SIZE }} inline align="left">Dre Cindy Lefebvre ({GetLicense(headerData?.preference)})&nbsp;</Typography>
                    <Typography sx={{ fontSize: REG_SIZE }} inline align="right">{item.expiration ? "Exp : " + item.expiration : ""}</Typography>
                </Grid>
            </CardContent>
        </Card>
        </Box>
    ) 
    

}


PrescriptionCard.propTypes = {
    headerData: PropTypes.object.isRequired,
    clientData: PropTypes.object.isRequired, 
    animalData: PropTypes.object.isRequired,
    extraData: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired
  };

export default PrescriptionCard