
import React from 'react'
import { CreateRx } from "./CreateRx"
import { ViewRx } from "./ViewRx"
import { useState } from "react"
import ClientHeader from "../../layout/ClientHeader"
import TabSelector from "../../layout/TabSelector"
import { GetRequiredView } from "../../Navigator"
import { useTranslation } from 'react-i18next'
import { GetAllHeader, GetNoSelectionHeader } from '../../view/HeadersUtils'

const RxManager = () => {
  const { t, i18n } = useTranslation();
  const [itemToEdit, setItemToEdit] = useState(null)
  const [itemNoToView, setItemNoToView] = useState(null)
  const [currentView, setCurrentView] = useState(GetRequiredView())
  const [availViews, setAvailViews] = useState(GetNoSelectionHeader(t))


    const handleEdit = (item) => {
      setItemToEdit(item)
      setAvailViews(GetAllHeader(t))
      setCurrentView("edit")
    }

    const saveCallback = (item) => {
      setItemToEdit(null)
      setItemNoToView(item.no)
      setAvailViews(GetNoSelectionHeader(t))
      setCurrentView("view")
    }
    return (
        <>
        <ClientHeader allowSelection={currentView !== "edit"}/>
        <TabSelector 
          onViewChange={setCurrentView}
          pageTitle="Prescription"
          availableView={availViews}
          currentView={currentView}
          />
          { currentView === "view" && <ViewRx itemNo={itemNoToView} handleEdit={handleEdit}/> }
          { currentView === "edit" && <CreateRx itemToEdit={itemToEdit} saveCallback={saveCallback}/> }
          { currentView === "create" && <CreateRx saveCallback={saveCallback}/> }
        </>
      );
}


export default RxManager