
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';

import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import { messageStore } from '../../common/InfoMessage';
import EmptyView from '../../view/EmptyView';
import { addRow, deleteRow, handleInputChange, setNextNoHandler } from '../../view/TableEditionHandler';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import VaccinTable from './VaccinTable';
import { emptyItem, emptyVaccin } from './BaseVaccin';
import DayPicker from '../../date/DatePicker';
import VaccineSelector from './VaccineSelector';
import { ReserveNo } from '../../../api/Common';
import { useTranslation } from 'react-i18next';
import { updateAnimal } from '../common/ItemUtils';

const itemNoFieldName = "vaccinNo"

export function CreateVaccine({itemToEdit, saveCallback}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [vaccine, setVaccine] = useState(isUpdate ? itemToEdit : emptyVaccin);


  React.useEffect(() => {
    handleSetNo();
  }, [vaccine, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && vaccine.no === "") {
      console.log("Need new no")
      setNextNoHandler(itemNoFieldName, vaccine, setVaccine)
    }
  }

  //console.log("curVaccine : ", vaccine)
  const internalHandleSave = async (event) => {
      event.preventDefault();
      const animalUpdate = JSON.parse(JSON.stringify(curAnimal.payload))
      var newVaccine = JSON.parse(JSON.stringify(vaccine))

      if (isUpdate) {
          const objIndex = animalUpdate.vaccines.findIndex(obj => obj.no === newVaccine.no);
          animalUpdate.vaccines[objIndex] = newVaccine
          updateAnimal(animalUpdate, newVaccine, isUpdate, saveCallback, dispatch, t, "vaccine")
          .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      } else {
          animalUpdate.vaccines = animalUpdate.vaccines ? [...animalUpdate.vaccines, newVaccine] : [newVaccine]
          await ReserveNo(newVaccine.no, itemNoFieldName)
          .then(() => updateAnimal(animalUpdate, newVaccine, isUpdate, saveCallback, dispatch, t, "vaccine"))
          .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
      }    
  }


  const getView =(header, curClient, curAnimal, vaccine) => {
    return (<Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
      { isUpdate &&
        <VaccineSelector animal={curAnimal} vaccine={vaccine} disable={true}/>
      }
              
      <form id="vaccineForm"  onSubmit={internalHandleSave}>
        <FlatHeaderRenderer 
            headerData={header} 
            clientData={curClient} 
            animalData={curAnimal}
            extraData={{
              "date": <DayPicker 
                value={ vaccine.date}
                inputChange={ (e) => handleInputChange(vaccine, setVaccine, null, "date", convertToSystemDateFormat(e))}
                /> }}/>
  
          <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align='center' variant="h5">
            Certificat de vaccination
          </Typography>  
          <VaccinTable 
            items={vaccine.items} 
            handleInputChange={(index, fieldName, value) => handleInputChange(vaccine, setVaccine, index, fieldName, value)} 
            deleteItem={(index) => deleteRow(vaccine, setVaccine, index)}
            isEditMode={true}
            isPrintMode={false}/>  
      <br/>
      <CreateButtonSection addRowHandler={() => addRow(vaccine, setVaccine, emptyItem)} />
  </form>
  </Paper>)
  }

  if ((vaccine === undefined || vaccine === null) || (curAnimal === undefined || curAnimal === null)) {
    return (<EmptyView title={t('animal.noSelection')} />)
  }

  return getView(header?.payload, curClient?.payload, curAnimal?.payload, vaccine)
  


}

