const sexeMap = { "fs": "Femelle stérilisée",
                "ff": "Femelle fertile",
                "ms": "Mâle stérilisé",
                "mf": "Mâle fertile",
                "un": "Inconnu"}

const GetSexeLabel = ({petSexe}) => {
  return sexeMap[petSexe]
}

const typeMap = { "dog": "Canin",
                "cat": "Félin",
                "other": "Autre"}
      
const GetTypeLabel = ({petType}) => {
  return typeMap[petType]
}

const emptyAnimal = {
  "no": "99999999999",
  "isActif": true, 
  "name":"",
  "birthdate":"",
  "weight":"",
  "WeightType": "lb",
  "type":"", 
  "race": "", 
  "sexe": "", 
  "color": "", 
  "micropuce": "",
  "vaccines": [],
  "consultations": [],
  "rxs": [],
  "events": [],
}



export {emptyAnimal, sexeMap, typeMap, GetSexeLabel, GetTypeLabel}
