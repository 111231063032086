
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';

import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import { messageStore } from '../../common/InfoMessage';
import EmptyView from '../../view/EmptyView';
import { addRow, deleteRow, handleInputChange, setNextNoHandler } from '../../view/TableEditionHandler';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import PrescriptionTable from './PrescriptionTable';
import { emptyItem, emptyPrescription, GetTypeSelector } from './BasePrescription';
import DayPicker from '../../date/DatePicker';
import RxSelector from './RxSelector';
import { ReserveNo } from '../../../api/Common';
import { updateAnimal } from '../common/ItemUtils';
import { useTranslation } from 'react-i18next';

const itemNoFieldName = "prescriptionNo"

export function CreateRx({itemToEdit, saveCallback}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [rx, setRx] = useState(isUpdate ? itemToEdit : emptyPrescription);


  React.useEffect(() => {
    handleSetNo();
  }, [rx, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && rx.no === "") {
      console.log("Need new no")
      setNextNoHandler(itemNoFieldName, rx, setRx)
    }
  }

    const internalHandleSave = async (event) => {
        event.preventDefault();
        const animalUpdate = JSON.parse(JSON.stringify(curAnimal.payload))
        var newRx = JSON.parse(JSON.stringify(rx))

        if (isUpdate) {
            const objIndex = animalUpdate.rxs.findIndex(obj => obj.no === newRx.no);
            animalUpdate.rxs[objIndex] = newRx
            updateAnimal(animalUpdate, newRx, isUpdate, saveCallback, dispatch, t, "rx")
            .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
        } else {
            animalUpdate.rxs = animalUpdate.rxs ? [...animalUpdate.rxs, newRx] : [newRx]
            await ReserveNo(newRx.no, itemNoFieldName)
            .then(() => updateAnimal(animalUpdate, newRx, isUpdate, saveCallback, dispatch, t, "rx"))
            .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
        }
    }


      const getView =(header, curClient, curAnimal, rx) => {
        return (<Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
          { isUpdate &&
            <RxSelector animal={curAnimal} rx={rx} disable={true}/>
          }
          <GetTypeSelector
            type={rx.type}
            onChangeHandler={(index, fieldName, value) => handleInputChange(rx, setRx, index, fieldName, value)}
            isEditMode={true}
            />
          <form id="rxForm"  onSubmit={internalHandleSave}>
            <FlatHeaderRenderer 
                headerData={header} 
                clientData={curClient} 
                animalData={curAnimal}
                extraData={{"title": "Prescription",
                "noTitle": "Numéro",
                "no": rx.no,
                "date": 
                    <DayPicker
                        value={ rx.date}
                        inputChange={ (e) => handleInputChange(rx, setRx, null, "date", convertToSystemDateFormat(e))}
                        />}}  
                />
      
            <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
                Détails de la prescription
            </Typography> 
            <PrescriptionTable 
                items={rx.items} 
                handleInputChange={(index, fieldName, value) => handleInputChange(rx, setRx, index, fieldName, value)} 
                deleteItem={(index) => deleteRow(rx, setRx, index)}
                isEditMode={true}
                isPrintMode={false}
                itemType={rx?.type}/>  
          <br/>
          <CreateButtonSection addRowHandler={() => addRow(rx, setRx, emptyItem)} />
      </form>
      </Paper>)
      }

      if ((rx === undefined || rx === null) || (curAnimal === undefined || curAnimal === null)) {
        return (<EmptyView title={t('animal.noSelection')} />)
      }

      return getView(header?.payload, curClient?.payload, curAnimal?.payload, rx)
      
    
    
}

