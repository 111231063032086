import React  from 'react';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MedicationIcon from '@mui/icons-material/Medication';
import FolderIcon from '@mui/icons-material/Folder';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AddCardIcon from '@mui/icons-material/AddCard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import CalculateIcon from '@mui/icons-material/Calculate';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PetsIcon from '@mui/icons-material/Pets';
import { useTranslation } from 'react-i18next';

const TooltipIconButton = ({children, onClick, title, defaultTitle}) => {
    const useTitle = title ? title : defaultTitle
    return (<Tooltip title={useTitle}>
                <IconButton onClick={onClick}>
                  {children}
                </IconButton>
            </Tooltip>)
}


export const AddItemIconContent = () => {return (<AddIcon />)}
export const EditItemIconContent = () => {return (<EditIcon />)}
export const SaveIconContent = () => {return (<SaveIcon />)}

export const RefreshIconContent = () => {return (<RefreshIcon/>)} 
export const RefreshIconTT = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
        defaultTitle="Rafraichir"
        >
            <RefreshIconContent/>
        </TooltipIconButton>)
}

export const CopyIconContent = () => {return (<ContentCopyIcon/>)} 
export const CopyIcon = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
        defaultTitle="Duppliquer"
            >
                <CopyIconContent/>
            </TooltipIconButton>)
}

export const DeleteIcon = ({onClick, title}) => {
    const { t, i18n } = useTranslation();
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
        defaultTitle={t('button.item.remove')}>
                <DeleteIconContent/>
            </TooltipIconButton>)
}
export const DeleteIconContent = () => {return (<DeleteForeverIcon />)}

export const ExportIconContent = () => {return (<PictureAsPdfIcon />)}
export const ReloadIconContent = () => {return (<ReplayIcon />)}


export const CalculIconContent = () => {return (<CalculateIcon />)}
export const CalculIcon = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
        defaultTitle="Calculette"
            >
                <CalculIconContent/>
            </TooltipIconButton>)
}

export const ClientIconContent = () => {return (<PeopleIcon />)}
export const AnimalIconContent = () => {return (<PetsIcon />)}



export const ConsultationIconContent = () => {return (<FolderIcon/>)} 
export const ConsultationIcon = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
            >
                <ConsultationIconContent/>
            </TooltipIconButton>)
}

export const PrescriptionIconContent = () => {return (<MedicationIcon/>)} 
export const PrescriptionIcon = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
            >
                <PrescriptionIconContent/>
            </TooltipIconButton>)
}

export const FactureIconContent = () => {return (<AddCardIcon/>)} 
export const FactureIcon = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
            >
                <FactureIconContent/>
            </TooltipIconButton>)
}

export const VaccinIconContent = () => {return (<VaccinesIcon/>)} 
export const VaccinIcon = ({onClick, title}) => {
    return (<TooltipIconButton
        onClick={onClick}
        title={title}
            >
                <VaccinIconContent/>
            </TooltipIconButton>)
}

export const Vue360IconContent = () => {return (<PeopleIcon/>)}

export const EventRequestIconContent = () => {return (<EventIcon/>)}
export const EventConfirmIconContent = () => {return (<EventAvailableIcon/>)}
export const HeaderIconContent = () => {return (<BusinessIcon/>)}