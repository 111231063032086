
import { GetElement, UpdateElement} from './S3Helper';

const ELEMENT_NAME = "common"
const KEY_NAME = "default"
function GetCommon() {
   return GetElement(ELEMENT_NAME, KEY_NAME)
}

async function UpdateCommon(data) {
   return await UpdateElement(ELEMENT_NAME, KEY_NAME, data)
}

function ReserveClientNo(currentNo) {
   return ReserveNo(currentNo, "clientNo")
}


async function ReserveAnimalNo(currentNo) {
   return await ReserveNo(currentNo, "animalNo")
}

async function ReserveNo(currentNo, fieldName) {
   const common = await GetCommon()
   if (common[fieldName] === undefined || currentNo === common[fieldName]) {
      common[fieldName] = currentNo + 1;
      console.log("New no :", fieldName, common[fieldName])

      await UpdateCommon(common)
      return currentNo
   } else {
      console.log("Missmatch in fieldNumber. NOTHING SAVE", fieldName)
      console.log("currentNo : " + currentNo)
      console.log("common : " + common[fieldName])
      throw new Error("Missmatch in fieldName " + fieldName)
   }
   
}

export {GetCommon, UpdateCommon, ReserveAnimalNo, ReserveClientNo, ReserveNo}