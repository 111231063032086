import React, { useEffect } from 'react'
import { Box,Typography } from '@mui/material'
import logo from '../img/logo-nb.png'
import { Logout as AuthLogout } from '../services/Authentif';
import {messageStore} from './common/InfoMessage'
import { useDispatch } from 'react-redux';
import { setUser } from './common/SessionSlice';
import { useTranslation } from 'react-i18next';

const Logout = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(setUser({}))
    logoutHandler();
  }, []); // Pass an empty array to only call the function once on mount.
 
 const logoutHandler = () => {

    AuthLogout().then(messageStore.sendMessage(t('session.loginSucces'), "info", t('session.logout')))
   
 }



  return (
    <>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
      <Box component="nav"
          sx={{ flexShrink: { sm: 0 } }} >
            {t('session.logout')}
        </Box>
        <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
        <div className="login">
          <Typography variant="body">{t('session.bye')}</Typography>
        </div>
      </Box>
      </Box>
    </>
  )
}

export default Logout