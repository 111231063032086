import React, { useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { Authenticate } from '../services/authenticate';
import logo from '../img/logo-nb.png'
import {messageStore} from './common/InfoMessage'
import { LogginProcess } from '../services/Authentif';
import { useDispatch } from 'react-redux';
import { setUser } from './common/SessionSlice';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const [t, i18n] = useTranslation()
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };

  const validation = () => {
    return new Promise((resolve, reject) => {

      if (email === '' && password === '') {
        
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.username'))
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.password'))
        resolve({ email: "Email is Required", password: "Password is required" });
      } else if (email === '') {
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.username'))
        resolve({ email: "Email is Required", password: "" });
      } else if (password === '') {
        messageStore.sendMessage(t('session.isRequired'), "warning", t('session.password'))
        resolve({ email: "", password: "Password is required" });
      } else if (password.length < 6) {
        messageStore.sendMessage(" doit avoir au moins 6 caractères", "warning", t('session.password'))
        resolve({ email: "", password: "must be 6 character" });
      } else {
        resolve({ email: "", password: "" });
      }
    });
  };

  const handleClick = () => {
    validation()
      .then((res) => {
        if (res.email === '' && res.password === '') {
          Authenticate(email, password)
          //LogginProcess(email,password)
          .then((data)=>{
            dispatch(setUser(email))
            messageStore.sendMessage(t('session.loginSucces'), "info", t('session.login'))
            Navigate('/');
          },(err)=>{
            //dispatch(setUser(null))
            messageStore.sendMessage(err.message, "error")
            console.log(err);
          })
          .catch(err=>{
            console.log(err)
            messageStore.sendMessage(err, "error")
            //dispatch(setUser(email))
          })
        }
      }, err => console.log(err))
      .catch(err => console.log(err));
  }

  return (
    <>
    

      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <img src={logo} className="App-MainLogo" alt="logo" width={1000} height={60}/>
      <Box
          component="nav"
          sx={{ flexShrink: { sm: 0 } }} >
            {t('session.title')}
        </Box>
        <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>
        <div className="login">
          <div className='form'>
            <div className="formfield">
              <TextField
                value={email}
                onChange={(e) => formInputChange("email", e.target.value)}
                label={t('session.username')}
              />
            </div>
            <div className='formfield'>
              <TextField
                value={password}
                onChange={(e) => { formInputChange("password", e.target.value) }}
                type="password"
                label={t('session.password')}
              />
            </div>
              
            <div className='formfield'>
              <Button type='submit' variant='contained' onClick={handleClick}>{t('session.login')}</Button>
            </div>
          </div>

          </div>
      </Box>
      </Box>
    </>
  )
}

export default Login