
import {ListElements, GetElement, HardDeleteElement} from './S3Helper';

const ELEMENT_NAME = "events"
class Events {
   List() {
      return new ListElements(ELEMENT_NAME)
   }

   Get(eventName) {
      return new GetElement(ELEMENT_NAME, eventName)
   }

   delete(key) {
      return new HardDeleteElement(ELEMENT_NAME, key)
   }
}
export default Events