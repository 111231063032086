import Typography from '@mui/material/Typography';
import React from 'react'
import DayPicker from "../date/DatePicker";
import { convertToSystemDateFormat } from '../date/DateUtils';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import {IsAdmin} from '../../services/authenticate'
import {sexeMap, typeMap} from './BaseAnimal'
import createAnimalAction from '../common/AnimalAction'
import { CopyIcon, DeleteIcon } from '../common/IconFactory';
import WeightInput from '../input/WeightInput';
import { GetWeightType } from '../common/CommonSlice';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AnimalSection({animalData, isEditMode, handleInputChange, deleteItem, index, addRow, navigateAction, clientData}) {
  const { t, i18n } = useTranslation();
  console.log("AnimalSection : ", animalData)
  return (<>
  <Box>
        <Typography key="iconTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="right">
          { isEditMode && 1 === "TODO" &&
            <>
              <CopyIcon onClick={(e) => addRow(animalData)} title="Duppliquer l'animal"/>             
              <DeleteIcon onClick={(e) => deleteItem(index)} title="Supprimer l'animal"/>
              
            </>
          }
          {
            !isEditMode && IsAdmin() && createAnimalAction(navigateAction, animalData, clientData)
          }
        </Typography>
        <Typography key="headerTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
          <TextField label="Numéro de l'animal" sx={{ my: 2, mx: 2 }}
              required
              disabled
              value={animalData.no} variant="standard"/>

          <TextField label={t('animal.item.nameLong')} sx={{ my: 2, mx: 2 }}
                required
                disabled={!isEditMode}
                fullWidth
                inputProps={{ maxLength: 60 }}
                onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                value={animalData.name} variant="standard"/>

          
          
          <InputLabel htmlFor="animalActif" sx={{ mx: 2 }}>Innactif
            <Checkbox label={t('animal.item.innactive')}  sx={{ mx: 2 }}
              id="animalActif"
              disabled={!isEditMode}
              checked={!animalData.isActif && animalData.isActif !== undefined} 
              onChange={(e) => isEditMode && handleInputChange(index, 'isActif', !e.target.checked)}
              />

              
          </InputLabel> 
          
          
          </Typography>
          <Typography key="regularTypo" sx={{mx: 2 }} color="text.secondary" align="left">
          
          <FormLabel id="type" sx={{  my: 2, mx: 2 }}>{t('animal.item.species')}
            <RadioGroup sx={{ mx: 2 }} id={"an_type_" + index}
              row
              aria-labelledby="type"
              disabled={!isEditMode}
              name="radio-buttons-group"
              value={animalData.type}
              onChange={(e) => isEditMode && handleInputChange(index, 'type', e.target.value)}
            >
              {Object.keys(typeMap).map((key, i) => (
                <FormControlLabel key={i} required disabled={!isEditMode} value={key} control={<Radio />} label={typeMap[key]} />
              ))}
            </RadioGroup>
          </FormLabel>
          


          <FormLabel id="sexe" sx={{  my: 2, mx: 2 }} >{t('animal.item.sex')}
            <RadioGroup sx={{mx: 2 }} id={"an_sexe_" + index}
              row
              aria-labelledby="sexe"
              disabled={!isEditMode}
              name="radio-buttons-group"
              value={animalData.sexe}
              onChange={(e) => isEditMode && handleInputChange(index, 'sexe', e.target.value)}
            >
              {Object.keys(sexeMap).map((key, i) => (
                <FormControlLabel key={i} required disabled={!isEditMode} value={key} control={<Radio />} label={sexeMap[key]} />
              ))}

            </RadioGroup>
          </FormLabel>
          

          <TextField label={t('animal.item.chip')} sx={{ my: 2, mx: 2 }}
                disabled={!isEditMode}
                inputProps={{ maxLength: 60 }}
                onChange={(e) => handleInputChange(index, 'micropuce', e.target.value)}
                value={animalData.micropuce} variant="standard"/>
          
    

            <TextField id="race" label={t('animal.item.race')} sx={{ my: 2, mx: 2 }}
            required
            disabled={!isEditMode}
            inputProps={{ maxLength: 60 }}
            onChange={(e) => handleInputChange(index, 'race', e.target.value)}
            value={animalData.race} variant="standard"/>
          

          

          <TextField label={t('animal.item.color')} sx={{ my: 2, mx: 2 }}
                required
                disabled={!isEditMode}
                inputProps={{ maxLength: 60 }}
                onChange={(e) => handleInputChange(index, 'color', e.target.value)}
                value={animalData.color} variant="standard"/>
        
          <WeightInput 
            weight={animalData.weight}
            weightType={animalData.weightType}
            isEditMode={isEditMode} 
            defaultWeightType={GetWeightType("other")}
            onWeightChange={(newValue) => handleInputChange(index, 'weight', newValue)}
            onWeightTypeChange={(newValue) => handleInputChange(index, 'weightType', newValue)}
            />

        </Typography> 
        
        <Typography key="dateTypo" sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
          <b>{t('animal.item.birth')} : </b> 
          { !isEditMode && animalData.birthdate ? animalData.birthdate : "Inconnue"}
          { isEditMode &&  
            <>
                <DayPicker 
                  label={t('dateSelectionLong')}
                  inputChange={(e) => handleInputChange(index, 'birthdate', convertToSystemDateFormat(e))}
                  required={true} 
                  value={animalData.birthdate}
                  maxDate={new Date()}/>

              <TextField  label={t('animal.item.birthInMonth')} sx={{ mx: 2 }}
                disabled={!isEditMode}
                min={0} max={998}  maxLength={3}
                onChange={(e) => handleInputChange(index, 'monthAge', e.target.value)}
                value={animalData.monthAge} type="number"/>
            </>
              }

        </Typography>
      </Box>
      </>)

}

export {AnimalSection}