import React  from 'react';
import { formatToDisplayFormatFromString } from "../date/DateUtils"
import { useState }  from 'react';
import Typography from '@mui/material/Typography';
import sign from '../../img/SignCindy.JPG'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import {IsAdmin} from '../../services/authenticate'
import {  GetLicense } from "../common/CommonSlice";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

function Signature({isPrintMode, date, displayLicense}) {
    const [t, i18n] = useTranslation()
    const curHeader = useSelector(state => state.common.header)?.payload?.preference
    const [showSignature, setShowSignature] = useState(true);
    const licenceNo = GetLicense(curHeader)
    return (
    <>
        <Grid container={!isPrintMode} spacing={1} direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ my: 1, mx: 2 }} color="text.secondary">
                <br/>
                {t('signature.prefix')}&nbsp;&nbsp;&nbsp;
                <img hidden={!showSignature} className="Signature" src={sign} width={105} height={40}/>
                _______________________________________&nbsp;&nbsp;&nbsp;
                {formatToDisplayFormatFromString(date) }

             {displayLicense &&
                <>&nbsp;&nbsp;&nbsp;# Licence : {licenceNo}</>
                
             }
            </Typography>

            {(!isPrintMode && IsAdmin) &&
                    <Tooltip title={t('signature.display')}>
                        <IconButton>
                            {showSignature && <VisibilityIcon onClick={() => setShowSignature(false)} /> }
                            {!showSignature && <VisibilityOffIcon onClick={() => setShowSignature(true)} /> }
                        </IconButton>
                    </Tooltip>
                }
        </Grid>
    </>)
}


export default Signature


