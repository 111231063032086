//https://medium.com/@skysharinghisthoughts/simplifying-multilingual-support-in-react-with-i18next-a-step-by-step-guide-34621de40cbd
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
i18n
  .use(Backend) // Enables loading translation files over HTTP.
  .use(LanguageDetector) // Detects user language.
  .use(initReactI18next) // Initializes the react-i18next plugin.
  .init({
    supportedLngs: [ 'fr'], // Languages we're supporting.
    fallbackLng: "fr", // Fallback language if user's language isn't supported.
    debug: true, // Logs info to the console.
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'], // Order of language detection.
      caches: ['cookie'], // Cache the detected language in cookies.
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json', // Path to the translation files.
    },
  });

export default i18n;