import React  from 'react';
import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material"
import { GetConsultTypeLabel } from "./BaseEvent"
import { GetSexeLabel, GetTypeLabel } from "../animals/BaseAnimal"
import { KG_DATA, LB_DATA, WeightText } from "../input/WeightInput"
import PropTypes from 'prop-types';

export const GetAnimalSection = ({event}) => {
  return (<>
    <b>Info animal</b>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Nom : </b> 
      { event.petName}
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Type : </b> 
      <GetTypeLabel petType={event.petType}/>
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Race : </b> 
      { event.petRace}
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Date de naissance : </b> 
      { event.petBirthdate ? event.petBirthdate : "Inconnue"}
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Poids : </b> 
      <WeightText 
        weight={event.weight || event.weightLb}
        weightType={event.weightLb ? LB_DATA : KG_DATA}
        displayWeightType={event.weightLb ? LB_DATA : KG_DATA}
          />
      { event.calculatedWeight }
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Couleur : </b> 
      { event.petColor}
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Sexe : </b> 
      <GetSexeLabel petSexe={event.petSexe}/>
    </Typography>
  </>)
}
GetAnimalSection.propTypes = {
  event: PropTypes.object.isRequired
};


export const GetClientSection = ({event}) => {
  return (<>
    <b>Info client</b>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Prénom : </b> 
      { event.firstname}
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Nom : </b> 
      { event.lastname}
      
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Téléphone : </b> 
      <a href={"tel:" + event.phone}>{ event.phone}</a>           
    </Typography>
    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Ligne d'adresse 1 : </b> 
      { event.address}
      
    </Typography>

    <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
      <b>Courriel : </b> 
      <a href={"mailto:" + event.email}>{event.email}</a>
    </Typography>
  </>)
}

GetClientSection.propTypes = {
  event: PropTypes.object.isRequired
};

export const GetConsultSection = ({consultEvent}) => {
    return (<>
      <b>Info sur la consultation</b>
      <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
        <b>Raison de la demande : </b> 
        { consultEvent.consultWhy}
      </Typography>
      <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
        <b>Plus d'information : </b> 
        { consultEvent.consultMore}
      </Typography>
      <Typography sx={{ my: 2, mx: 2 }} color="text.secondary" align="left">
        <b>Type de demande de consultation : </b> 
        <GetConsultTypeLabel consultType={consultEvent.consultType}/>
      </Typography>
    </>)

  }
  GetConsultSection.propTypes = {
    consultEvent: PropTypes.object.isRequired
  };

  export const GetManageSection = ({allowNewClient, onChange, sendZoomLink, sendCalendarEvent, sendEmail, isNewClient}) => {
    return (<>
      <b>Gestion de la demande</b>
      <form >
      <FormGroup sx={{ my: 2, mx: 2 }}>
        <FormControlLabel control={<Switch id="zoomLink"  defaultChecked onChange={(event) => onChange("sendZoomLink", event.target.checked)} checked={sendZoomLink}/>} label="Générer un lien Zoom" />
        <FormControlLabel control={<Switch id="calendarLink" onChange={(event) => onChange("sendCalendarEvent", event.target.checked)} checked={sendCalendarEvent}/>} label="Générer un événement calendrier pour le client" />
        <FormControlLabel control={<Switch id="sendEmail" onChange={(event) => onChange("sendEmail", event.target.checked)} checked={sendEmail}/>} label="Envoyer un courriel avec les détails de la réservation" />
        <FormControlLabel disabled={!allowNewClient} control={<Switch id="newClient" onChange={(event) => onChange("isNewClient", event.target.checked)} defaultChecked checked={isNewClient} />} label="Nouveau client" />
      </FormGroup>
      </form>
    </>)
  }

  GetAnimalSection.propTypes = {
    allowNewClient: PropTypes.bool, 
    onChange: PropTypes.object.isRequired, 
    sendZoomLink: PropTypes.object.isRequired, 
    sendCalendarEvent: PropTypes.object.isRequired, 
    sendEmail: PropTypes.object.isRequired, 
    isNewClient: PropTypes.object.isRequired
  };
  