import React  from 'react';
import { ConsultationIcon, FactureIcon, PrescriptionIcon, VaccinIcon } from './IconFactory';


function createAnimalAction(navigateAction, animalData) {
    return (<>
    { navigateAction && 
    <>
        <VaccinIcon
            title="Créer un vaccin"
            onClick={(e) => createVaccin(navigateAction, animalData)}
        />
        <FactureIcon
            title="Créer une facture"
            onClick={(e) => createBill(navigateAction, animalData)}
        />
        <PrescriptionIcon
            title="Créer une prescription"
            onClick={(e) => createPrescription(navigateAction, animalData)}
        />
        <ConsultationIcon
            title="Créer une consultation"
            onClick={(e) => createConsultation(navigateAction, animalData)}
        />
    </>
    }
     </>)     
}

function navigateTo(navigateAction, target, animalData, clientDate) {
    navigateAction(target, { state: createNavigationData(animalData, clientDate) });
}

function createBill(navigateAction, animalData, clientDate) {
    navigateTo(navigateAction, '/factures', animalData, clientDate)
}
function  createVaccin(navigateAction, animalData, clientDate) {
    navigateTo(navigateAction, '/vaccins', animalData, clientDate)
} 

function  createPrescription(navigateAction, animalData, clientDate) {
    navigateTo(navigateAction, '/prescriptions', animalData, clientDate)
}

function  createConsultation(navigateAction, animalData, clientDate) {  
    navigateTo(navigateAction, '/consultations', animalData, clientDate)
}


function createNavigationData(animalData, clientDate) {
    return {
      currentView: "create",
      selectedClient: clientDate,
      selectedAnimal: animalData}
  }




export default createAnimalAction