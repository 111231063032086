
import React  from 'react';
import { useState } from "react"
import TabSelector from "../layout/TabSelector"
import MainOverviewPage from "./MainOverviewPage"

const OverviewManager = () => {
    const [currentView, setCurrentView] = useState("view")
    //const [availViews, setAvailViews] = useState([{key: 0, title: "Statistique", name: "view"}])
    const availViews = [{key: 0, title: "Statistique", name: "view"}]
    return (
        <>
            <TabSelector 
                onViewChange={setCurrentView}
                pageTitle="Overview"
                availableView={availViews}
                currentView={currentView}
                />
            <MainOverviewPage />    
        </>
    
      );
}


export default OverviewManager