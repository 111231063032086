import React from 'react'
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';

import Divider from '@mui/material/Divider';
import { formatToSystemDate } from '../../date/DateUtils';
import WeightInput from '../../input/WeightInput';
import { styled } from '@mui/material/styles';
import { GetWeightType } from '../../common/CommonSlice';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const TextFieldStyle = styled(TextField)({ marginTop:16, marginBottom:16 });


const emptyConsultation = {
    "isActif": true, 
    "date": formatToSystemDate(new Date()), 
    "consultation": {
        "consultWhy": "Vaccination", 
        "appetit": "Normal", 
        "vomissement": "Non", 
        "urines": "Normal", 
        "selles": "Normal", 
        "reactionVaccin": "Non", 
        "knowProblem": "Non", 
        "medicaments": "Non", 
        "anamnese": "Dernier vaccin de rage : il y a plus de 3 ans", 
        "etatChair": 9, 
        "weight": "", 
        "weightType": "lb", 
        "temperature": "", 
        "fc": "", 
        "fr": "", 
        "mugueuses": "Roses et humides", 
        "trc": "< 2 sec", 
        "hydratation": "Normale", 
        "etatGenerale": "Alerte", 
        "yeux": "Normal", 
        "oreilles": "Normal", 
        "nez": "Normal", 
        "gueuleDents": "", 
        "cardio": "Absence de souffle et arythmie, bon pouls", 
        "respiratoire": "Patron normal, BBV's normaux", 
        "abdomen": "Souple et confortable, sans anomalie", 
        "teguments": "Normal", 
        "myoarthrosquelettique": "Démarche normale, extension des hanches confortable", 
        "noeudsLymphatiques": "Normaux", 
        "neurologique": "État mental normal", 
        "reproducteur": "Normal", 
        "toucherRectal": "NÉ", 
        "thyroide": "", 
        "analyse": "BEG pour la vaccination \nMicropuce fonctionnelle, lue avant la vaccination", 
        "plan": "Vaccin de rage SC MPD, rappel 1 an \nDiscuté des réactions vaccinales \nAvisé des anomalies de l'examen \nCIL4098"}, 
    "no": "",
    "link": {}}


/*const emptyConsultationSection = {
    "consultWhy": "Vaccination", 
    "appetit": "Normal", 
    "vomissement": "Non", 
    "urines": "Normal", 
    "selles": "Normal", 
    "reactionVaccin": "Non", 
    "knowProblem": "Non", 
    "medicaments": "Non", 
    "anamnese": "Dernier vaccin de rage : il y a plus de 3 ans", 
    "etatChair": 9, 
    "weight": "", 
    "weightType": "lb", 
    "temperature": "", 
    "fc": "", 
    "fr": "", 
    "mugueuses": "Roses et humides", 
    "trc": "< 2 sec", 
    "hydratation": "Normale", 
    "etatGenerale": "Alerte", 
    "yeux": "Normal", 
    "oreilles": "Normal", 
    "nez": "Normal", 
    "gueuleDents": "", 
    "cardio": "Absence de souffle et arythmie, bon pouls", 
    "respiratoire": "Patron normal, BBV's normaux", 
    "abdomen": "Souple et confortable, sans anomalie", 
    "teguments": "Normal", 
    "myoarthrosquelettique": "Démarche normale, extension des hanches confortable", 
    "noeudsLymphatiques": "Normaux", 
    "neurologique": "État mental normal", 
    "reproducteur": "Normal", 
    "toucherRectal": "NÉ", 
    "thyroide": "", 
    "analyse": "BEG pour la vaccination \nMicropuce fonctionnelle, lue avant la vaccination", 
    "plan": "Vaccin de rage SC MPD, rappel 1 an \nDiscuté des réactions vaccinales \nAvisé des anomalies de l'examen \nCIL4098"}
*/



function GetConsultationSection({consultationData, animalData, isEditMode, handleInputChange, isPrintMode}) {
  const curHeader = useSelector(state => state.common.header)?.payload?.preference


    const variant = isEditMode ? 'outlined' : 'standard'
    return <>
      <Box>
        <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
          <TextFieldStyle label="Motif de consultation" 
              variant={variant}
              fullWidth
              disabled={!isEditMode} InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange('consultWhy', e.target.value)}
              value={consultationData.consultWhy} />

          <TextFieldStyle label="Appétit" 
              variant={variant}
              fullWidth
              disabled={!isEditMode} InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange('appetit', e.target.value)}
              value={consultationData.appetit} />
          
          <TextFieldStyle label="Vomissement" 
              variant={variant}
              fullWidth
              disabled={!isEditMode} InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange('vomissement', e.target.value)}
              value={consultationData.vomissement} />
          
          <TextFieldStyle label="Urines" 
              variant={variant}
              fullWidth
              disabled={!isEditMode} InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange('urines', e.target.value)}
              value={consultationData.urines} />
          <TextFieldStyle label="Selles" 
              variant={variant}
              fullWidth
              disabled={!isEditMode} InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange('selles', e.target.value)}
              value={consultationData.selles} />
            

          <TextFieldStyle label="Réaction vaccinale antérieure"
            variant={variant}
            fullWidth
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('reactionVaccin', e.target.value)}
            value={consultationData.reactionVaccin} 
            multiline/>

          <TextFieldStyle label="Problème de santé connue"
            variant={variant}
            fullWidth
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('knowProblem', e.target.value)}
            value={consultationData.knowProblem} 
            multiline/>
            
          <TextFieldStyle label="Médicaments"
            variant={variant}
            fullWidth
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('medicaments', e.target.value)}
            value={consultationData.medicaments}
            multiline />
          

          <TextFieldStyle label="Anamnèse"
            variant={variant}
            fullWidth
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('anamnese', e.target.value)}
            value={consultationData.anamnese} 
            multiline/>
        </Typography>
        <Divider/>
        <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
          <Box width="50%">
            État de chair
            <Slider
            aria-label="État de chair"
            value={consultationData.etatChair}
            getAriaValueText={() => {return consultationData.etatChair}}
            onChange={(e) => handleInputChange('etatChair', e.target.value)}
            disabled={!isEditMode}
            step={1}
            marks
            min={0}
            max={9}
            valueLabelDisplay="on"
          />
          </Box>
          
          <WeightInput 
            weight={consultationData.weight}
            weightType={consultationData.weightType}
            isEditMode={isEditMode} 
            defaultWeightType={GetWeightType(curHeader, "consultation")}
            onWeightChange={(newValue) => handleInputChange('weight', newValue)}
            onWeightTypeChange={(newValue) => handleInputChange('weightType', newValue)}
            />

          <TextField label="Température" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('temperature', e.target.value)}
            value={consultationData.temperature} type="number"/>
          
          <TextField label="FC" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('fc', e.target.value)}
            value={consultationData.fc} type="number"/>
          
          <TextField label="FR" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('fr', e.target.value)}
            value={consultationData.fr}/>


          <TextField label="Mugueuses" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('mugueuses', e.target.value)}
            value={consultationData.mugueuses} />

          <TextField label="TRC" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('trc', e.target.value)}
            value={consultationData.trc} />
            
          <TextFieldStyle label="Hydratation" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('hydratation', e.target.value)}
            value={consultationData.hydratation} />

          <TextField label="État général" sx={{ my: 2, mx: 2 }}
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('etatGenerale', e.target.value)}
            value={consultationData.etatGenerale} />

          <TextFieldStyle label="Yeux" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('yeux', e.target.value)}
            value={consultationData.yeux} 
            multiline/>

          <TextFieldStyle label="Oreilles" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('oreilles', e.target.value)}
            value={consultationData.oreilles} 
            multiline/>

          <TextFieldStyle label="Nez" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('nez', e.target.value)}
            value={consultationData.nez} 
            multiline/>

          <TextFieldStyle label="Gueule/Dents"
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('gueuleDents', e.target.value)}
            value={consultationData.gueuleDents} 
            multiline/>

          <TextFieldStyle label="Cardiovasculaire"
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('cardio', e.target.value)}
            value={consultationData.cardio} 
            multiline/>

          <TextFieldStyle label="Respiratoire"
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('respiratoire', e.target.value)}
            value={consultationData.respiratoire} 
            multiline/>
          
          <TextFieldStyle label="Abdomen" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('abdomen', e.target.value)}
            value={consultationData.abdomen} 
            multiline/>

          <TextFieldStyle label="Téguments" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('teguments', e.target.value)}
            value={consultationData.teguments} 
            multiline/>

          <TextFieldStyle label="Myoarthrosquelettique" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('myoarthrosquelettique', e.target.value)}
            value={consultationData.myoarthrosquelettique} 
            multiline/>

          <TextFieldStyle label="Noeuds lymphatiques" 
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('noeudsLymphatiques', e.target.value)}
            value={consultationData.noeudsLymphatiques}
            multiline/>

          <TextFieldStyle label="Neurologique"
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('neurologique', e.target.value)}
            value={consultationData.neurologique} 
            multiline/>

          <TextFieldStyle label="Reproducteur"
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('reproducteur', e.target.value)}
            value={consultationData.reproducteur} 
            multiline/>

          <TextFieldStyle label="Toucher rectal"     
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('toucherRectal', e.target.value)}
            value={consultationData.toucherRectal} 
            multiline/>

        {(animalData && animalData.type === "cat") && 
          <TextFieldStyle label="Thyroïde"
            variant={variant}
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            fullWidth
            onChange={(e) => handleInputChange('thyroide', e.target.value)}
            value={consultationData.thyroide} 
            multiline/>
              }

        </Typography>
        <Divider/>
        <Typography sx={{ my: 2, mx: 4 }} color="text.secondary" align="left" >
          <TextFieldStyle label="Analyse"
            variant={variant}
            fullWidth
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('analyse', e.target.value)}
            value={consultationData.analyse} 
            multiline/>

          <TextFieldStyle label="Plan"
            variant={variant}
            fullWidth
            disabled={!isEditMode} InputLabelProps={{ shrink: true }}
            onChange={(e) => handleInputChange('plan', e.target.value)}
            value={consultationData.plan} 
            multiline/>

        </Typography>
      </Box>
    </>
}

GetConsultationSection.propTypes = {
  consultationData: PropTypes.object.isRequired,
  animalData: PropTypes.object.isRequired, 
  isEditMode: PropTypes.bool,
  isPrintMode: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired
};


export { emptyConsultation, GetConsultationSection }