import jsPDF from 'jspdf';
import { messageStore } from './common/InfoMessage';

const handleDownload = (setIsPrintMode, pdfContentRef, pdfName) => {
    //this.handlePrintMode(true)
    setIsPrintMode(true)
    SavePDF(pdfContentRef.current, pdfName, () => {messageStore.sendMessage("Pdf créé avec succès", "info")})
    .then(() =>  setIsPrintMode(false))
  }

function SavePDF(currentRef, fileName, callback) {
    const originalWidth = currentRef.width
    currentRef.width = 780
    console.log("Will save : ", fileName)
  
    return generatePdf(currentRef, fileName, callback).then(() => {
        currentRef.width = originalWidth
    })
}


function generatePdf(htmlElement, fileName, callback) {
    let jsPdf = new jsPDF('p', 'pt', 'letter');
    //var htmlElement = document.getElementById('doc-target');
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
        callback: function (jsPdf) {
            jsPdf.save(fileName, { returnPromise: true });

            if (callback) {
                callback(fileName, jsPdf.output())
            }
            // to open the generated PDF in browser window
            // window.open(jsPdf.output('bloburl'));
        },
        margin: [20, 20, 20, 20],
        autoPaging: 'text',
        html2canvas: {
            allowTaint: true,
            dpi: 300,
            letterRendering: true,
            logging: false,
            scale: .73,
            windowWidth: 780,
            width: 780
        }
    };

    return jsPdf.html(htmlElement, opt);

}



export {SavePDF, handleDownload}