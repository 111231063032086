import React from 'react'
import CompanyCard from './CompanyCard';
import ClientCard from '../clients/ClientCard';
import AminalCard from '../animals/AminalCard';
import ExtraHeader from './ExtraHeader';
import { Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import './header.css'


function FlatHeaderRenderer({headerData, clientData, animalData, extraData, 
    extraChildren, isPrintMode, canRefresh, 
    clientUpdateCallback, animalUpdateCallback, displayHeaderLeft}) {


  const leftXs = displayHeaderLeft ? 11 : 9
  const rightXs = displayHeaderLeft ? 9 : 11


  return (
    <Grid container spacing={0} columns={20} className='grid-container' spacing='5' sx={{ my: 1 }}>
      <Grid item xs={leftXs} className='grid-item'>
          <Paper elevation={2}>
            <CompanyCard displayLogo={displayHeaderLeft} headerData={headerData} isPrintMode={isPrintMode}/>
          </Paper>
      </Grid>
      <Grid item xs={rightXs} className='grid-item' >
        <Paper elevation={2}>
            <ExtraHeader displayLogo={!displayHeaderLeft} extraData={extraData} extraChildren={extraChildren} isPrintMode={isPrintMode}/>
          </Paper>
      </Grid>
      <Grid item xs={leftXs} className='grid-item'>
        <Paper elevation={2}>
            <AminalCard animalData={animalData} clientKey={clientData.key} isPrintMode={isPrintMode} updateCallback={animalUpdateCallback}/>
          </Paper>
      </Grid>
      <Grid item xs={rightXs} className='grid-item'>
        <Paper elevation={2}>
            <ClientCard clientData={clientData} isPrintMode={isPrintMode} updateCallback={clientUpdateCallback}/>
          </Paper>
      </Grid>
    </Grid>)
  }


  FlatHeaderRenderer.propTypes = {
  headerData: PropTypes.object.isRequired,
  clientData: PropTypes.object.isRequired,
  animalData: PropTypes.object.isRequired,
  extraData: PropTypes.object,
  isPrintMode: PropTypes.bool,
  canRefresh: PropTypes.bool,
  clientUpdateCallback: PropTypes.func,
  animalUpdateCallback: PropTypes.func,
  displayHeaderLeft: PropTypes.bool,
};


export default FlatHeaderRenderer