
import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';

import { useDispatch, useSelector } from 'react-redux';
import {CreateButtonSection} from '../../view/CreateButtonSection';
import { messageStore } from '../../common/InfoMessage';
import EmptyView from '../../view/EmptyView';
import { setNextNoHandler } from '../../view/TableEditionHandler';
import { convertToSystemDateFormat } from '../../date/DateUtils';
import { emptyConsultation, GetConsultationSection } from './BaseConsultation';
import DayPicker from '../../date/DatePicker';
import ConsultationSelector from './ConsultationSelector';
import { ReserveNo } from '../../../api/Common';
import { updateAnimal } from '../common/ItemUtils';
import { useTranslation } from 'react-i18next';

const itemNoFieldName = "consultationNo"

export function CreateConsultation({itemToEdit, saveCallback}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isUpdate = (itemToEdit)
  const header = useSelector(state => state.common.header)
  const curClient = useSelector(state => state.client.client)
  const curAnimal = useSelector(state => state.client.animal)   
  const [consultation, setConsultation] = useState(isUpdate ? itemToEdit : emptyConsultation);


  React.useEffect(() => {
    handleSetNo();
  }, [consultation, isUpdate]);


  const handleSetNo = () => {
    if (!isUpdate && consultation.no === "") {
      console.log("Need new no")
      setNextNoHandler(itemNoFieldName, consultation, setConsultation)
    }
  }

    console.log("curConsultation : ", consultation)
    
    // Fonction pour mettre à jour les valeurs des champs
    const handleInputChange = (fieldName, value) => {
      if (consultation.consultation[fieldName] !== value) {  
        console.log("current consultation : ", consultation)
        var newConsultation = JSON.parse(JSON.stringify(consultation))
        newConsultation.consultation[fieldName] = value;
        setConsultation(newConsultation)
      }
    };
    const handleDateChange = (value) => {
      if (consultation.date !== value) {  
        var newConsultation = JSON.parse(JSON.stringify(consultation))
        newConsultation.date = value;
        setConsultation(newConsultation)
      }
    };

    const internalHandleSave = async (event) => {
        event.preventDefault();
        const animalUpdate = JSON.parse(JSON.stringify(curAnimal.payload))
        var newConsultation = JSON.parse(JSON.stringify(consultation))

        if (isUpdate) {
            const objIndex = animalUpdate.consultations.findIndex(obj => obj.no === newConsultation.no);
            animalUpdate.consultations[objIndex] = newConsultation
            updateAnimal(animalUpdate, newConsultation, isUpdate, saveCallback, dispatch, t, "consultation")
            .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
        } else {
            animalUpdate.consultations = animalUpdate.consultations ? [...animalUpdate.consultations, newConsultation] : [newConsultation]
            await ReserveNo(newConsultation.no, itemNoFieldName)
            .then(() => updateAnimal(animalUpdate, newConsultation, isUpdate, saveCallback, dispatch, t, "consultation"))
            .catch((error) => messageStore.sendMessage(error.message, "error", t('saveError')))
        }
    }


      const getView =(header, curClient, curAnimal, consultation) => {
        return (<Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }} component={'span'}>
            
          { isUpdate &&
            <ConsultationSelector animal={curAnimal} consultation={consultation} disable={true}/>
          }      
          <form id="consultationForm"  onSubmit={internalHandleSave}>
          <FlatHeaderRenderer 
            headerData={header} 
            clientData={curClient} 
            animalData={curAnimal}
            extraData={{"title": "Consultation",
                  "date": 
                    <DayPicker 
                      value={ consultation.date}
                      inputChange={ (e) => handleDateChange(convertToSystemDateFormat(e))}
                      />,
                  "noTitle": "Numéro",
                  "no": consultation.no}}/>
      
          <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" variant="h5">
              Détails de la consultation
          </Typography>
          <GetConsultationSection 
            consultationData={consultation.consultation} 
            animalData={curAnimal}
            isEditMode={true} handleInputChange={handleInputChange}/>
          <br/>
          <CreateButtonSection />
      </form>
      </Paper>)
      }

      if ((consultation === undefined || consultation === null) || (curAnimal === undefined || curAnimal === null)) {
        return (<EmptyView title={t('animal.noSelection')} />)
      }

      return getView(header?.payload, curClient?.payload, curAnimal?.payload, consultation)
}

