import { sexeMap, typeMap } from '../animals/BaseAnimal';
import { RefreshIconTT } from "../common/IconFactory";
import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Clients from '../../api/Clients';
import AnimalSelector from './AnimalSelector';

function AminalCard({animalData, clientKey, isPrintMode, canRefresh, updateCallback}) {
    return <>
            { animalData && JSON.stringify(animalData) !== JSON.stringify({}) &&  
              v2(animalData, clientKey, isPrintMode, canRefresh, updateCallback)
          }
            </>
}
        

function v1(animalData, clientKey, isPrintMode, canRefresh, updateCallback) {
  return (<Typography sx={{ my: 0, mx: 2 }} color="text.secondary" align="left">
    <table>
      <tr>
        <td colSpan="2"><h3>Informations du patient
          {(canRefresh && ! isPrintMode) && 
              <UpdateAnimalDialog 
                currentAnimal={animalData}
                clientKey={clientKey} 
                updateCallback={updateCallback}
              /> }</h3></td>
      </tr>
      <tr>
        <td>Nom :</td>
        <td>{animalData.name}</td>
      </tr>
      <tr>
        <td>Naissance :</td>
        <td>{animalData.birthdate ? animalData.birthdate : "Inconnue"}</td>
      </tr>
      {animalData.type && <tr>
        <td>Espèce :</td>
        <td>{typeMap[animalData.type]}</td>
      </tr>
      }
      {animalData.race && <tr>
        <td>Race :</td>
        <td>{animalData.race}</td>
      </tr>
      }
      {animalData.sexe && <tr>
        <td>Sexe :</td>
        <td>{sexeMap[animalData.sexe]}</td>
      </tr>
      }
      {animalData.color && <tr>
        <td>Couleur :</td>
        <td>{animalData.color}</td>
      </tr>
      }
      {animalData.micropuce && <tr>
        <td># micropuce</td>
        <td>{animalData.micropuce}</td>
      </tr>
      }
    </table>
</Typography>)
}
function v2(animalData, clientKey, isPrintMode, canRefresh, updateCallback) {
  return (
  <Grid container spacing={0} columns={10} 
    className='header-grid-container' 
    sx={{ my: 0, mx: 0, color: 'gray', textAlign: 'left', typography: 'body2' }}>
    <Grid item xs={10} sx={{ color: 'rgb(90, 90, 90)' , typography: 'body1' }} className='header-grid-item'>
      <Typography sx={{ my: 1 }} variant="h6">
        Informations du patient
      </Typography>
    </Grid>

    <Grid item xs={4} className='header-grid-item' >Nom :</Grid>
    <Grid item xs={6} className='header-grid-item'>{animalData.name}</Grid>

    <Grid item xs={4} className='header-grid-item'>Naissance :</Grid>
    <Grid item xs={6} className='header-grid-item'>{animalData.birthdate ? animalData.birthdate : "Inconnue"}</Grid>

    <Grid item xs={4} className='header-grid-item'>Espèce :</Grid>
    <Grid item xs={6} className='header-grid-item'>{typeMap[animalData.type]}</Grid>

    {animalData.race && <>
      <Grid item xs={4} >Race :</Grid>
      <Grid item xs={6} >{animalData.race}</Grid>
    </>}
    {animalData.sexe && <>
      <Grid item xs={4} >Sexe :</Grid>
      <Grid item xs={6} >{sexeMap[animalData.sexe]}</Grid>
    </>}
    {animalData.color && <>
      <Grid item xs={4} >Couleur :</Grid>
      <Grid item xs={6} >{animalData.color}</Grid>
    </>}
    {animalData.micropuce && <>
      <Grid item xs={4} ># micropuce :</Grid>
      <Grid item xs={6} >{animalData.micropuce}</Grid>
    </>}
  </Grid>)
}



export const UpdateAnimalDialog = ({ currentAnimal, clientKey,  updateCallback}) => {
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedAnimal, setSelectedAnimal] = useState(currentAnimal);

  const handleOpen = () => {
    new Clients().Get(clientKey).then((data) => {
      setSelectedClient(data)
      setOpen(true);
    })
  }
  const handleUpdate = () => {
    updateCallback(selectedAnimal)
    handleClose()
  }

  const handleClose = () => setOpen(false);

  return (
    <>
      <RefreshIconTT
        onClick={handleOpen}/>
      <Dialog open={open} onClose={handleClose}>
        
        <DialogContent>
        <Box>
          <Typography variant="h6">Sélection de l'animal</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>          
            <AnimalSelector 
              key={selectedClient != null ? selectedClient.clientNo : "-1"} 
              animalData={currentAnimal}
              updateCallback={setSelectedAnimal}
              clientKey={selectedClient.key}/>
          </Box>

        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdate}>Mettre à jour</Button>
          <Button onClick={handleClose}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};


export default AminalCard