import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FlatHeaderRenderer from '../../header/FlatHeaderRenderer';
import { formatToDisplayFormatFromString } from '../../date/DateUtils'
import { useSelector } from 'react-redux';
import {ViewButtonAnimalPartSection, ViewButtonSection} from '../../view/ViewButtonSection';
import { handleDownload } from '../../GeneratePDF';
import EmptyView from '../../view/EmptyView';
import RxSelector from './RxSelector';
import { Grid } from '@mui/material';
import { ExportIconContent } from '../../common/IconFactory';
import Signature from '../../input/Signature';
import PrescriptionCard from './PrescriptionCard';
import PrescriptionTable from './PrescriptionTable';
import { useTranslation } from 'react-i18next';

export function ViewRx({itemNo, handleEdit}) {
    const header = useSelector(state => state.common.header)
    const curClient = useSelector(state => state.client.client)
    const curAnimal = useSelector(state => state.client.animal)

    const pdfContentRef = React.createRef();
    const pdfCardContentRef = React.createRef();
    const [isPrintMode, setIsPrintMode] = useState(false);
    const [rx, setRx] = useState(itemNo && itemNo >= 0 ? curAnimal?.payload?.rxs?.find(obj => obj.no === itemNo) : null);

    if (rx && (curAnimal?.payload?.rxs === undefined ||  curAnimal?.payload?.rxs.findIndex(obj => obj.no === rx.no) === -1)) {
        setRx(null)
        return (<GetEmptyView animal={curAnimal} setItem={setRx} />)
      }
  

    const getPdfName = () => {
      return rx.no + "-" + curClient.payload.name + "-" + curAnimal.payload.name + ".pdf"
    }

      const getView =(header, client, animal, rx, isPrintMode) => {
        return (
            <Paper id="visualisation" sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
                    <Typography  color="text.secondary" align='center'>
                      <RxSelector animal={animal} rx={rx} setRx={setRx}/>
                    </Typography>
                    <div ref={pdfContentRef} border="1" style={{maxWidth: isPrintMode ? '780px' : ''}}>
                    <FlatHeaderRenderer 
                        isPrintMode={isPrintMode}
                        headerData={header} 
                        clientData={client} 
                        animalData={animal}
                        extraData={{"title": "Prescription",
                        "noTitle": "Numéro",
                        "no": rx.no,
                        "date": formatToDisplayFormatFromString(rx.date)}}  
                        />
                    
                    <Typography sx={{ my: 1, mx: 2 }} color="text.secondary" align='center' variant="h5">
                        Détails de la prescription
                    </Typography> 
                    <PrescriptionTable  
                        items={rx.items} 
                        isEditMode={false}
                        isPrintMode={isPrintMode}
                        itemType={rx.type}/>
                    <br/>
                    <Signature isPrintMode={isPrintMode} date={rx.date} displayLicense={true} />
                    </div>

                
                <Grid ref={pdfCardContentRef} container  columnSpacing={{  my: 3, mx: 3   }}>
                { rx.type === "2" && rx.items.map((row, index) => (
                    <PrescriptionCard key={index}
                    headerData={header ? header.payload : null} 
                    clientData={client} 
                    animalData={animal} 
                    extraData={{
                        "no": rx.prescriptionNo,
                        "date": formatToDisplayFormatFromString(rx.date)}}
                    item={row}
                        />
                ))}
                </Grid>
                <ViewButtonAnimalPartSection 
                    isEditMode={true}
                    animal={animal}
                    allowDelete={true}
                    itemArrayName="rxs"
                    selectedItem={rx}
                    handleEdit={() => handleEdit(rx)}
                    handleDownload={() => handleDownload(setIsPrintMode, pdfContentRef, getPdfName())}
                />
                { rx.type === "2" &&
                    <Button variant="contained"
                        onClick={() => handleDownload(setIsPrintMode, pdfCardContentRef, getPdfName())} >
                        <ExportIconContent/>Télécharger les étiquettes
                    </Button>
                }
                
            </Paper>
       );
       }

    if (rx === undefined || rx === null) {
      return (<GetEmptyView animal={curAnimal} setItem={setRx} />)
    }

    return getView(header?.payload, curClient?.payload, curAnimal?.payload, rx, isPrintMode)
    
}

const GetEmptyView = ({animal, setItem}) => {
  const { t, i18n } = useTranslation();
  return (
    <EmptyView title={t('rx.noSelection')}>
      <RxSelector animal={animal?.payload} setRx={setItem}/>
    </EmptyView>
  )
}

