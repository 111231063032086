import { configureStore } from '@reduxjs/toolkit'
import LoadingReducer from './LoadingSlice'
import ClientReducer from './ClientSlice'
import CommonReducer from './CommonSlice'
import SessionReducer from './SessionSlice'
import ViewReducer from './ViewSlice'

export default configureStore({
  reducer: {
    loading: LoadingReducer,
    client: ClientReducer,
    common: CommonReducer,
    view: ViewReducer,
    session: SessionReducer
  }
})