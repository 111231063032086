import {Parser} from 'xml2js';
import env from '../env/env.json';
import axios from 'axios';
import {GetAccessToken} from '../services/authenticate'
//import { useSelector } from 'react-redux';
//import { getJwtToken } from '../component/common/SessionSlice';

const parser = new Parser();

function GetKeys(xmlResponse) {

  //console.log(xmlResponse);
  let keys = []
  parser.parseString(xmlResponse, (err, result) => {
    (result.ListBucketResult.Contents.map((item) => {
      const elementKey = item.Key[0]
      if (!elementKey.endsWith("/")) {
        var fileNameArray = elementKey.split("/")
        fileNameArray = fileNameArray.slice((fileNameArray.length -1) * -1)
        const fileName =  fileNameArray.join("/")

        if (fileName !== null && fileName !== "") {
          keys.push(fileName)
        }
      }
    }));
  });

  //console.log("Keys : ", keys)
  return keys;
}


function ListElements(elementName) {
 const token = GetAccessToken() 
 const APIEndPoint =  env.adminApi + elementName
 console.log("Fetch " + elementName);
  return fetch(APIEndPoint,{headers: {
          "X-API-Key": env.adminKey,
          "Content-Type": "application/xml",
          "Authorization": 'Bearer ' + token
       }
       })
   .then((response) => response.text())
   .then((data) => new GetKeys(data))
   .catch((err) => {
      console.log("Error ListElements : ", err);
      return [""]
   })
}

function GetElement(elementName, key) {
  const token = GetAccessToken() 

  if (!token) {
    console.log("INFO : No session yet")
   return new Promise( () => {return ""})
  }

  if (key === "" ) {
    console.log("INFO : GetElement with null key")
   return new Promise( () => {return ""})
  }

  const APIEndPoint =  env.adminApi + elementName + '/' + encodeURIComponent(key)
  //console.log("Fetch GetElement : ", key);
  return fetch(APIEndPoint,{headers: {
          "X-API-Key": env.adminKey,
          "Content-Type": "application/xml",
          "Authorization": 'Bearer ' + token
       }
       })
   .then((response) => response.json())
   .catch((err) => {
      console.log("Error GetElement :", err);
      return ""
   })
}

/*
function HeadElement(elementName, key) {
  console.log("HeadElement : ", key)
  //const userSession = useSelector(state => state.session.userSession)?.payload
  const token = GetAccessToken() //getJwtToken(userSession)

  if (!token) {
    console.log("INFO : No session yet")
   return new Promise( () => {return ""})
  }

  if (key === "" ) {
    console.log("INFO : GetElement with null key")
   return new Promise( () => {return ""})
  }

  const APIEndPoint =  env.adminApi + elementName + '/' + encodeURIComponent(key)
  //console.log("Fetch GetElement : ", key);

  axios.head(APIEndPoint ,
    {headers: {
      "X-API-Key": env.adminKey,
      "Authorization": 'Bearer ' + token
        }
    })
    .then((response) => {
      console.log("response : ", response)}
    )

}
*/

function InsertElement(elementName, key, formData) {
  key = escapeKey(key)
  return new GetElement(elementName, key)
  .then((existingElement) => 
  {
    if (existingElement != null && existingElement !== "") {
      throw new Error("Impossible d'insérer un élément qui existe déjà. Clé : " + key)
      //return new Promise(function(resolve) {resolve({status: "500", statusText: "Impossible d'insérer un élément qui existe déjà. Clé : " + key})})
    }
     return internalPutElement(elementName, key, formData)
  })
}

function internalPutElement(elementName, key, formData) {
  const token = GetAccessToken() 
  const APIEndPoint = env.adminApi + elementName + '/'
  const publish = async (key, formData) => {
      try {
        const encodeKey = encodeURIComponent(key)
        const response = await axios.put(APIEndPoint + encodeKey, formData ,
          {headers: {
            "X-API-Key": env.adminKey,
            "Authorization": 'Bearer ' + token
              }
          });
        response.data = formData
        console.log('Response:', response.data);
        return response
      } catch (error) {
        console.error('Error InsertElement :', error);
        throw new Error(error.message)
      }
  };
  return publish(key, formData)
}


function UpdateElement(elementName, key, formData) {
  key = escapeKey(key)
  return new GetElement(elementName, key)
  .then((existingElement) => 
  {
    if (existingElement === null || existingElement === "") {
      throw new Error("Impossible de mettre à jour l'élément car il est introuvable. Clé : " + key)
      //return new Promise(function(resolve) {resolve({status: 404, statusText: "Impossible de mettre à jour l'élément car il est introuvable. Clé : " + key})})
    }
    return internalPutElement(elementName, key, formData)
  })
}

function HardDeleteElement(elementName, key) {
  const token = GetAccessToken() 
  key = escapeKey(key)

     const APIEndPoint = env.adminApi + elementName + '/'
     const del = async (key) => {
         try {
           const encodeKey = encodeURIComponent(key)
           const response = await axios.delete(APIEndPoint + encodeKey ,
             {headers: {
               "X-API-Key": env.adminKey,
               "Authorization": 'Bearer ' + token
                 }
             });
           console.log('Response:', response.data);
           return response
         } catch (error) {
          console.error('Error HardDeleteElement :', error);
          throw new Error(error.message)
          //return new Promise(function(resolve) {resolve({status: error.code, statusText: error.message, detail: error})})
         }
     };

     return del(key)
}

function escapeKey(key) {
  return key.trim().replaceAll("é", "e").replaceAll("è", "e").replaceAll("ê", "e")
}



function generateNewKey(clientNo, elementNo, clientName, petName) {
  const baseName = clientNo + "/" + elementNo + "___" + clientName;

  return escapeKey(petName ? baseName + "___" + petName: baseName).replaceAll(" ", "_") 
}




export {GetKeys, ListElements, GetElement, InsertElement, UpdateElement, generateNewKey, escapeKey, HardDeleteElement}