import { Autocomplete, TextField } from "@mui/material"
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

const BillSelector = ({client, bill, setBill, disable}) => {
    const { t, i18n } = useTranslation();
    return (
    <Autocomplete
      disabled={disable}
      disablePortal
      disableClearable
      onChange={(event, newValue) => setBill(newValue)}
      sx={{ width: 500, my: 5, mx: 2 }}
      value={bill}
      options={client?.bills ? client.bills : []}
      getOptionLabel={selectOptionTitre}
      renderInput={(params) => <TextField {...params} label={t('billing.selectorTitle')}/>}
    />)
  }
  
  const selectOptionTitre = (option) => {
    var paidLabel = option.isPaid ? "" : " - Non payé" 
    paidLabel = "#" + option.no + "  "+ option.date + paidLabel
    paidLabel = option.animal?.name ? paidLabel + " (" + option.animal.name + ")" : paidLabel

    return paidLabel
  }

  BillSelector.propTypes = {
    client: PropTypes.object.isRequired,
    bill: PropTypes.object.isRequired,
    setBill: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };
  

  export default BillSelector