import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import { useLocation} from 'react-router-dom';
import logo from '../img/logo-nb.png'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';

import {IsAdmin as isUserAdmin} from '../services/authenticate'
import { AnimalIconContent, ClientIconContent, ConsultationIconContent, EventConfirmIconContent , EventRequestIconContent, FactureIconContent, HeaderIconContent, PrescriptionIconContent, VaccinIconContent, Vue360IconContent} from './common/IconFactory';
import { useTranslation } from 'react-i18next';

//Event navigation
export const eventsRequestUrl = "/eventsRequest";
export const eventsConfirmUrl = "/eventsConfirm";
//Client navigation
export const clientUrl = "/clients";
export const animalUrl = "/animals";
export const billUrl = "/factures";
export const view360Url = "/vue360";
//Medic navigation
export const consultationUrl = "/consultations";
export const rxUrl = "/prescriptions";
export const vaccineUrl = "/vaccins";

//Other navigation
export const headerUrl = "/headers";
export const overviewUrl = "/overview";


export const GetRequiredView = () => {
  const location = useLocation();
  const askView = location.state?.view

  return askView ? askView : "view"
}




const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const [t, i18n] = useTranslation()
  const { ...other } = props;

  const location = useLocation();
  const isAdmin = isUserAdmin();
  let navigate = useNavigate(); 

  let categories = [
    {
      id: t('navigation.eventSection'),
      needAdmin : true,
      icon: <CalendarMonthIcon/>,
      children: [
        { id: t('navigation.eventPage'), nav: eventsRequestUrl, icon: <EventRequestIconContent/> , active: location.pathname === eventsRequestUrl, needAdmin : true},
        { id: t('navigation.eventConfirmPage'), nav: eventsConfirmUrl, icon:  <EventConfirmIconContent/> , active: location.pathname === eventsConfirmUrl, needAdmin : true},
      ],
    },
    {
      id: t('navigation.clientSection'),
      needAdmin : false,
      icon: <ContactPageIcon />,
      children: [
        { id: t('client.title'), nav: clientUrl, icon: <ClientIconContent/>, active: location.pathname === clientUrl, needAdmin: false },
        { id: t('animal.title'), nav: animalUrl, icon: <AnimalIconContent/>, active: location.pathname === animalUrl, needAdmin: false },
        { id: t('billing.title'), nav: billUrl, icon: <FactureIconContent/>, active: location.pathname === billUrl, needAdmin : true },
        { id: 'Vue 360', nav: view360Url, icon: <Vue360IconContent/>, active: location.pathname === view360Url, needAdmin: false },
      ],
    },
    {
      id: t('navigation.medicSection'),
      icon: <MedicalInformationIcon />,
      needAdmin : false,
      children: [
        { id: t('consultation.title'), nav: consultationUrl, icon: <ConsultationIconContent/>, active: location.pathname === consultationUrl, needAdmin : true },
        { id: t('rx.title'), nav: rxUrl, icon: <PrescriptionIconContent/>, active: location.pathname === rxUrl, needAdmin : true },
        { id: t('vaccine.title'), nav: vaccineUrl, icon: <VaccinIconContent/> , active: location.pathname === vaccineUrl, needAdmin : true }
      ],
    },
    {
      id: t('navigation.otherSection'),
      needAdmin : true,
      icon: <SettingsIcon />,
      children: [
        /*{ id: 'Calendrier', nav: "/calendar", icon: <TimerIcon /> ,  active: location.pathname === "/calendar"},*/
        { id: t('header.title'), nav: headerUrl, icon: <HeaderIconContent/>,  active: location.pathname === headerUrl, needAdmin : true},
        /*{ id: 'Autre', nav: "/other", icon: <SettingsIcon />,  active: location.pathname === "/other" },*/
      ],
    },
  ];



  function itemClick(destination) {
    console.log("Nav to : " + destination)
    navigate(destination)
  }


  return (
    <Drawer variant="permanent" {...other}>
      <List key="main-list" disablePadding>
        <ListItem key="icon-logo" sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          <img src={logo} className="App-MainLogo" alt="logo"/>
        </ListItem>
        { isAdmin &&
          <ListItem key="overview-list" sx={{ ...item, ...itemCategory }} >
            <ListItemButton selected={location.pathname === overviewUrl} sx={item} >
              <ListItemIcon onClick={function(e) {itemClick(overviewUrl)}}>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText onClick={function(e) {itemClick(overviewUrl)}}>Vue d'ensemble</ListItemText>
            </ListItemButton>
          </ListItem>
        }
        {categories.map(({ id, children, needAdmin, icon }) => (

          <>
          { (needAdmin === false || (needAdmin && isAdmin)) &&
            <Box key={id} sx={{ bgcolor: '#101F33' }}>
              <ListItem key={id} sx={{ py: 2, px: 3 }}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText sx={{ color: '#fff' }}>{id}</ListItemText>
              </ListItem>
              {children.map(({ id: childId, icon, active, nav, needAdmin }) => (
                <>
                  {
                    (needAdmin === false || (needAdmin && isAdmin)) &&
                
                  <ListItem disablePadding key={childId}>
                      <ListItemButton selected={active} sx={item}>
                        <ListItemIcon onClick={function(e) {itemClick(nav)}} >{icon}</ListItemIcon>
                        <ListItemText onClick={function(e) {itemClick(nav)}}>{childId}</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  }
                </>

                  
                


              ))}
              <Divider sx={{ mt: 2 }} />
            </Box>
          }
          </>
        ))}
      </List>
    </Drawer>
  );
}
