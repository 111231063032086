export const GetAllHeader = (t) => {
  const ALL_VIEW = [{key: 0, title: t('view.create'), name: "create"},
    {key: 2, title: t('view.edit'), name: "edit"},
    {key: 1, title: t('view.view'), name: "view"}]
  
    return ALL_VIEW
}    

 export const GetNoSelectionHeader =  (t) => {
  const NO_SELECTION_VIEW = [{key: 0, title: t('view.create'), name: "create"},
    {key: 1, title: t('view.view'), name: "view"}]

    return NO_SELECTION_VIEW
  }