import React  from 'react';
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import {  fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import userpool from '../component/userpool';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Face3Icon from '@mui/icons-material/Face3';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {userStore} from '../component/common/Session'
import env from '../env/env.json';
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import avCindy from '../img/avatar/cindy.jpg'
import { Avatar } from '@mui/material';

const RESTRICT_ATTRIBUTE = "restricted_client"

var adminRight = false;
var restrictedClients = [99999]
//var adminRight = true;
//var restrictedClients = []


function setupAcces(email) {
    //jrsaucier@live.ca
    if (email === "jrsaucier@live.ca") {        
        adminRight = false
        restrictedClients = [3, 4, 5, 6]
    } else if (email === "o.lemire@hotmail.com") {        
        adminRight = false
        restrictedClients = [7]
    } else {
        userStore.isAdmin(true)
        adminRight = true
        restrictedClients = []
    }
    userStore.setIsAdmin(adminRight)
    userStore.setRestrictedClients(restrictedClients)
}

function IsAdmin() {
    //const isAdmin = useSyncExternalStore(userStore.subscribe, userStore.isAdmin);
    setupAcces(userpool.getCurrentUser()?.getUsername())
    const isAdmin = userStore.isAdmin();
    return isAdmin
}
function GetRestrictedClients() {
    const restrictedClients = userStore.getRestrictedClients();
    //const restrictedClients = useSyncExternalStore(userStore.subscribe, userStore.getRestrictedClients);
    return restrictedClients
}

function GetUserIcon(username) {
    if (!username) {
        return <NoAccountsIcon/>
    }
    const un = username
    if (un === "cindy.lefebvre@gmail.com") {
        
        return <Avatar src={avCindy}  />
    } else if (un === "compagnat") {
        return <AdminPanelSettingsIcon/>
    }

    return <PersonOutlineIcon />
}


const Authenticate=(Email,Password)=>{
    //userStore.setUsername(Email)
    setupAcces(Email)

    return new Promise((resolve,reject,newPassword)=>{
        const user=new CognitoUser({
            Username:Email,
            Pool:userpool
        });

        const authDetails= new AuthenticationDetails({
            Username:Email,
            Password
        });
        user.authenticateUser(authDetails,{
            onSuccess:(result)=>{
                console.log("login successful");
                
                RefreshUserData(Email)
                resolve()               
            },
            onFailure:(err)=>{
                console.log("login failed",err);
                reject(err);
            }, 
            newPasswordRequired: userAttr => {
                console.log("Need to create new password");
                reject({isFirstLoging: true, user: user, userAttr: userAttr})
                
            }
        });
    });
};

const RefreshUserData=(Email)=>{
    //const dispatch = useDispatch()
    //dispatch(setUser(Email))
    //userStore.setUsername(Email)
    setupAcces(Email)
    
}

const changePassword = (newPassword, user, userAttr) => {
    console.log("Call change password")
    user.changePassword(newPassword, userAttr, {
        onSuccess:(result)=>{
            console.log("New password successful");
        },
        onFailure:(err)=>{
            console.log("New password failed",err);
        }
    });
  };



const GetAwsCreds = async (region) => {
    const idToken = GetAccessToken()


    const cognitoidentity = new CognitoIdentityClient({
        credentials : fromCognitoIdentityPool({
            client : new CognitoIdentityClient({"region": env.aws_cognito_region}),
            identityPoolId : env.aws_cognito_identity_pool_id,
            "region": env.aws_cognito_region,
            logins: {
              ["cognito-idp.ca-central-1.amazonaws.com/" + env.REACT_APP_USER_POOL_ID] : idToken
            }
         }),
    });
    const credentials = await cognitoidentity.config.credentials()

    credentials.region = region


    return (
        {
            "credentials": {
                "accessKeyId": credentials.accessKeyId,
                "secretAccessKey": credentials.secretAccessKey,
                "sessionToken": credentials.sessionToken,
                "expiration": credentials.expiration
            },
            "region": region
    })
}

function GetAccessToken() {
    //const username =  userStore.getUsername()
    //const username = useSelector(state => state.session.user)
    //console.log("username : ", username)
    const token = userStore.getIdToken()

    
    //if (username === null || username === undefined) {
        if (userpool.getCurrentUser() === null) {
            console.log("You are currently not loggin")
            return null
        } /*else {
            console.log("Context out of sync")
            RefreshUserData(userpool.getCurrentUser().username)
        }
        return null
    }*/
  
    if (token !== null && token !== undefined) {
      //Validate current token
      if (new Date().getTime()/1000 > token.payload.exp) {
        userStore.setSession(null)
        fetchNewAccessToken()
      }
    } else {
        fetchNewAccessToken()
    }
     
  
    return userStore.getIdToken()?.jwtToken
}



  


function fetchNewAccessToken() {
    console.log("Fetch new token")
    userpool.getCurrentUser()?.getSession((error, session) => {
        if (error !== null) {
          userStore.setSession(null)
          console.log("fetchNewAccessToken : ", error)
          alert(error);
        } else {
          console.log("New token define!")
          userStore.setSession(session)
        }
      });
    return userStore.getIdToken()
}
  

export {GetAwsCreds, Authenticate, changePassword, IsAdmin, GetRestrictedClients, GetUserIcon, GetAccessToken};
