
import React  from 'react';
import { useState } from "react"
import TabSelector from "../layout/TabSelector"
import { ViewHeader } from './ViewHeader';
import { useTranslation } from 'react-i18next';


const HeaderManager = () => {
    const [t, i18n] = useTranslation()
    const [currentView, setCurrentView] = useState("view")
    const [availViews, setAvailViews] = useState([{key: 0, title: "Préférence de compagnie", name: "view"}])

    return (
        <>
            <TabSelector 
            onViewChange={setCurrentView}
            pageTitle={t('header.title')}
            availableView={availViews}
            currentView={currentView}
            />
            <ViewHeader />
        </>
        );
}


export default HeaderManager

