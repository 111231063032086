import Animals from "../../../api/Animals";
import { setAnimal } from "../../common/ClientSlice";
import { messageStore } from "../../common/InfoMessage";

export const deleteItem = (itemArrayName, itemToDelete, animal, dispatch, t) => {
  
    var animalUpdate = JSON.parse(JSON.stringify(animal))
  
  
    const objIndex = animal[itemArrayName].findIndex(obj => obj.no === itemToDelete.no);
    delete animalUpdate[itemArrayName][objIndex]
    animalUpdate[itemArrayName] = animalUpdate[itemArrayName].filter(n => n)
  
    return new Animals().Update(animalUpdate).then((result) => {
        if (result.status === 200) {
          new Animals().Get(animalUpdate.key).then(setAnimal).then(dispatch)
          messageStore.sendMessage(t('message.deleteSuccess'))
        } else {
          messageStore.sendMessage(result.statusText, "error", t('message.deleteError'))
        }
    })
  
  }
  

  export const updateAnimal = (animalUpdate, newItem, isUpdateItem, saveCallback, dispatch, t, tContext) => {
    return new Animals().Update(animalUpdate).then((result) => {
      if (result.status === 200) {
        new Animals().Get(animalUpdate.key).then(setAnimal).then(dispatch).then(() => {saveCallback(newItem)})
        messageStore.sendMessage("Consultation créé avec succès")
        if (isUpdateItem) {
          messageStore.sendMessage(t(tContext + '.updateSuccess'))
        } else {
          messageStore.sendMessage(t(tContext + '.saveSuccess'))
        }
      } else {
        messageStore.sendMessage(result.statusText, "error","Erreur lors de la création")
      }
  })
  }