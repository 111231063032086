import { emptyAnimal } from "../animals/BaseAnimal";
import { emptyClient } from "../clients/BaseClient";
import { emptyEvent } from "../events/BaseEvent";

const emptyEventConfirm = {
    "isActif": true, 
    "eventNo": "",
    "client": emptyClient,
    "animal": emptyAnimal,
    "calendar": {
        "eventId": "",
        "startDate": "",
        "startDateDisplay": "",
        "duration": 30,
        "summary": ""
      },
      event: emptyEvent,
      isNewClient: false,
      isNewAnimal: false,
      sendZoomLink: false,
      sendCalendarEvent: false,
      sendEmail: true
  }


function createEventConfirm(eventNo, originalEvent, selectedClient, isNewClient, isNewAnimal, sendZoomLink, sendCalendarEvent, sendEmail) {
    const summary = "Rendez-vous pour " + selectedClient.animal.name
    const key = selectedClient.clientNo + "/" + eventNo + "___" + originalEvent.selectedSystemDate

    return {
        "key": key,
        "isActif": true, 
        "eventNo": eventNo,
        "client": selectedClient,
        "calendar": {
            "eventId": originalEvent.eventId,
            "startDate": originalEvent.selectedSystemDate,
            "startDateDisplay": originalEvent.selectedDate,
            "duration": 30,
            "summary": summary
           },
        event: originalEvent,
        isNewClient: isNewClient,
        isNewAnimal: isNewAnimal,
        sendZoomLink: sendZoomLink,
        sendCalendarEvent: sendCalendarEvent,
        sendEmail: sendEmail
    }


}

export {emptyEventConfirm, createEventConfirm}