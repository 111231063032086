import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import React, { useEffect, useState }  from 'react';

import userpool from './component/userpool';
import Login from './component/Login';
import Logout from './component/Logout';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import HeaderManager from './component/headers/HeaderManager';
import CustomThemeComponent from './component/CustomThemeComponent'
import Navigator, { animalUrl, billUrl, clientUrl, consultationUrl, eventsConfirmUrl, eventsRequestUrl, headerUrl, overviewUrl, rxUrl, vaccineUrl, view360Url } from './component/Navigator';
import Top from './component/layout/Top';
import Copyright from './component/Copyright';
import {GetAccessToken, IsAdmin} from './services/authenticate'
import InfoMessage from './component/common/InfoMessage'
import View360 from './component/vue360/View360';
import { setHeader } from './component/common/CommonSlice';
import { useDispatch, useSelector } from 'react-redux';
import RxManager from './component/medic/rxs/RxManager';
import BillManager from './component/factures/BillManager';
import VaccineManager from './component/medic/vaccines/VaccineManager';
import ClientManager from './component/clients/ClientManager';
import AnimalManager from './component/animals/AnimalManager';
import ConsultationManager from './component/medic/consultations/ConsultationManager';
import OverviewManager from './component/overview/OverriewManager';
import EventsManager from './component/events/EventsManager';
import Headers from './api/Headers';
import EventsConfirmManager from './component/eventsConfirm/EventsConfirmManager';
import { LoadingScreen } from './component/common/LoadingSlice';


const drawerWidth = 256;

function App() {
  const username = userpool.getCurrentUser()?.getUsername()
  const dispatch = useDispatch()

  const [client, setClient] = useState(null);
  const [animal, setAnimal] = useState(null);
  const [facture, setFacture] = useState(null);


  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const curHeader = useSelector(state => state.common.header)
  if (!curHeader && username) {
    new Headers().Get("default.json").then(setHeader).then(dispatch)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(()=>{
      if(username){
        console.log("Loggué");
        
      } else {
        console.log("pas loggué");
        navigate('/login')
      }
  },[]);

  return (
     
      <div className="App">
        <div className='main-section'>


        </div>


        <CustomThemeComponent>
          <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <CssBaseline />
            
            {userpool.getCurrentUser() && 
              <Box component="nav"
                  sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                  >
                  <Navigator key='nav-temp'
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  /> 
                  <Navigator key='nav-permanent'
                    PaperProps={{ style: { width: drawerWidth } }}
                    sx={{ display: { sm: 'block', xs: 'none' } }}
                  />
                </Box>
              }
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                {userpool.getCurrentUser() && 
                  <>
                    <Top onDrawerToggle={handleDrawerToggle}
                        username={username}
                    />
                  </>
                }
                
                <InfoMessage />
                <LoadingScreen />
                  <Routes >
                  
                  { GetAccessToken() && !IsAdmin() && 
                    <Route exact path="/" element={<ClientManager />}/>
                  }
                    { GetAccessToken() && IsAdmin() && 
                    <>
                      <Route exact path="/" element={
                        <EventsManager />
                        }/> 
                      <Route exact path={headerUrl} element={<HeaderManager />}/>
                      <Route exact path={consultationUrl} element={
                        <ConsultationManager />}/>
                      <Route exact path={eventsRequestUrl} element={<EventsManager />}/>
                      <Route exact path={eventsConfirmUrl} element={<EventsConfirmManager />}/>
                      <Route exact path={rxUrl} element={
                        <RxManager />
                      }/>
                      <Route path={billUrl} element={<BillManager/>} />

                      <Route path={vaccineUrl} element={
                          <VaccineManager />
                        }/>
                      <Route path={view360Url} element={<View360 />}/>
                      <Route path={overviewUrl} element={
                          <OverviewManager />
                      }/>
                    </>
                    }

                    { GetAccessToken() &&
                      <>
                        <Route exact path={clientUrl} element={<ClientManager />}/>
                        <Route exact path={animalUrl} element={<AnimalManager/> } />
                        <Route path='/logout' element={<Logout />}/>
                      </>
                    }
                    <Route path='/login' element={<Login />}/>
                    
                    
                  </Routes>
              
                <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
                  <Copyright />
                </Box>
              </Box>



            

          </Box>
        </CustomThemeComponent>

        

      </div>
  );
}

export default App;
