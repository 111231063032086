
import {ListElements, GetElement, InsertElement, UpdateElement, escapeKey, HardDeleteElement} from './S3Helper';

const ELEMENT_NAME = "clients"

class Clients {

   List() {
      return new ListElements(ELEMENT_NAME)
   }

   Get(clientName) {
      return new GetElement(ELEMENT_NAME, clientName)
   }

   Insert(clientData) {
      const newKey = this.generateNewKey(clientData)
      clientData = {...clientData,
         key: newKey
      }
      return new InsertElement(ELEMENT_NAME, newKey, clientData)
   }

   Update(clientData) {
      const key = this.getFullKey(clientData)
      if (clientData.key !== key) {
         clientData = {...clientData,
            key: key
         }
      }
      return new UpdateElement(ELEMENT_NAME, key, clientData)
   }

   getFullKey(clientData) {
      if (clientData.key !== null && clientData.key !== undefined) {
         return clientData.key
      }
      return this.generateNewKey(clientData)
   }
   delete(key) {
      return new HardDeleteElement(ELEMENT_NAME, key)
   }

   generateNewKey(clientData) {
      const no = clientData.no
      const clientName = clientData.name
      return escapeKey(no + "___" + clientName).replaceAll(" ", "_") 
   }
}
export default Clients