import { createSlice } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    header: null    
  },
  reducers: {
    setHeader: (state, headerData) => {
      console.log("store : setHeader", headerData)
      state.header = headerData
    }
  }
})

export const GetWeightConf = (conf) => {
  return conf?.weight
}
export const GetWeightType = (conf, type) => {
  return GetWeightConf(conf) ? GetWeightConf(conf)[type] : null
}
export const GetPaiementEmail = (conf) => {
  return conf?.paiement_email
}
export const GetLicense = (conf) => {
  return conf?.license
}

// Action creators are generated for each case reducer function
export const { setHeader } = commonSlice.actions

export default commonSlice.reducer