import React from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Clients from '../../api/Clients';
import Events from '../../api/Events';
import { PieChart } from '@mui/x-charts/PieChart';
import { ClientIconContent, ConsultationIconContent, EventRequestIconContent, FactureIconContent, PrescriptionIconContent, VaccinIconContent } from '../common/IconFactory';
import { GetCommon } from '../../api/Common';

class MainOverviewPage extends React.Component {
  constructor(props) {
    super(props);

    new GetCommon("default.json").then((item) => this.updateCommon(item))
    new Clients().List().then((itemList) => this.updateStat("nbClients", itemList))
    new Events().List().then((itemList) => this.parseEventStat(itemList))
  }

  
  state = {
    nbClients: -1,
    nbFactures: -1,
    nbPrescriptions: -1,
    nbVaccins: -1,
    nbConsultations: -1,
    nbEvents: -1,
    nbEventsUrgences: -1,
    common: {
      factureNo: -1,
      prescriptionNo: -1,
      vaccinNo: -1,
      consultationNo: -1
    }
  }

  parseEventStat(eventList) {
    const newData = this.state;
    newData["nbEvents"] = eventList.length;
    newData["nbEventsUrgences"] = eventList.filter((key) => key.startsWith("Urgence---")).length

    this.setState(newData)
  }

  updateStat(statName, itemList) {
    const newData = this.state;
    newData[statName] = itemList.length;
    
    this.setState(newData)
  }
  updateCommon(itemList) {
    const newData = this.state;
    newData.common = itemList;
    
    this.setState(newData)
  }

  render() {
    return (    
      <Paper sx={{ maxWidth: 936, margin: 'auto', overflow: 'hidden' }}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} 
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <ClientIconContent/> Information client :
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
          Nombre de client : <b>{this.state.nbClients}</b> 
        </Typography>
        

        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} 
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <EventRequestIconContent/> Information d'événement :
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          <PieChart
            series={[
              { highlightScope: { faded: 'global', highlighted: 'item' },
                data: [
                  { id: 0, value:(this.state.nbEvents-this.state.nbEventsUrgences), label: "Évênement régulier" },
                  { id: 1, value:(this.state.nbEventsUrgences), label: 'Évênement urgent' }
                ],
              },
            ]}
            width={600}
            height={200}
          />
          Total : <b>{this.state.nbEvents}</b>
        </Typography>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }} 
        >
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
               Information sur les activités vétérinaires :
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
          <FactureIconContent/> Nombre de facture : <b>{this.state.common?.factureNo}</b>
        </Typography>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
          <PrescriptionIconContent/> Nombre de prescription : <b>{this.state.common?.prescriptionNo} </b>
        </Typography>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
          <VaccinIconContent/> Nombre de vaccins : <b>{this.state.common?.vaccinNo}</b>
        </Typography>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
          <ConsultationIconContent/> Nombre de consultations : <b>{this.state.common?.consultationNo}</b>
        </Typography>
      </Paper>
    );
  }
}

export default MainOverviewPage;