import React  from 'react';
import { Autocomplete, TextField } from "@mui/material"
import Animals from "../../api/Animals"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { addLoadingCount, subLoadingCount } from '../common/LoadingSlice';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AnimalSelector = ({clientNo, animal, setAnimal, disable, loadAnimal}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const [clientNoLoad, setClientNoLoad] = useState(null)
  const [optionsAnimals, setOptionsAnimals] = useState([])

    useEffect(() => {
      if (clientNo && clientNo !== clientNoLoad) {
        dispatch(addLoadingCount())
        new Animals().List()
          .then((allAnimals) => {
            const optAnimals = allAnimals.filter(a => a.substring(0, a.indexOf("/")) === clientNo.toString())
            console.log("optionsAnimals : ", optAnimals)
            setOptionsAnimals(optAnimals)
            setClientNoLoad(clientNo)
          })
          .finally(()=>dispatch(subLoadingCount()))
      }
    
    }, [clientNo]);



    const internalOnChange = (event, newKey) => {
      if (loadAnimal) {
        dispatch(addLoadingCount())
        new Animals().Get(newKey)
          .then(setAnimal)
          .finally(()=>dispatch(subLoadingCount()))
      } else {
        setAnimal(newKey)
      }
    }


    return (<Autocomplete
        disabled={disable}
        disableClearable
        onChange={internalOnChange}
        sx={{ width: 500 }}
        value={animal === undefined ? null : animal}
        options={optionsAnimals}
        getOptionLabel={selectOptionTitre}
        renderInput={(params) => <TextField {...params} label={t('animal.selectorTitle')}/>}
      />)
  }
  

  const selectOptionTitre = (option) => {
    if (!option) {
      return null
    }

    let displayValue = "# " + option.substring(option.indexOf("/") + 1).replaceAll("___", " - ").replaceAll("_", " ");
    return displayValue;
  }

  AnimalSelector.propTypes = {
    clientNo: PropTypes.number.isRequired,
    animal: PropTypes.string.isRequired,
    loadAnimal: PropTypes.bool,
    setAnimal: PropTypes.func.isRequired,
    disable: PropTypes.bool
  };
  
  export default AnimalSelector